import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Grid, CircularProgress, Divider, Typography, TextField, Box, InputAdornment, IconButton, Accordion, AccordionDetails, AccordionSummary, Select, MenuItem, ListSubheader, Checkbox } from '@mui/material';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import UploadIcon from '@mui/icons-material/Upload';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';

import { dispatchException, dispatchMessage } from 'helper/snackbar';

import { CustomTabsControlled } from 'components/Tabs';

import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';

import {
  EVICT_INVOICE_QUERIES,
  INVOICE_APPROVE_MUTATION,
  INVOICE_VIEW_QUERY,
  INVOICE_VIEW_MEDIA_QUERY,
  REFETCH_INVOICE_QUERIES,
  LIST_INVOICE_COSTCENTRES_QUERY,
  INVOICE_REJECT_SEM_MUTATION,
  INVOICE_GENERATE_MUTATION,
} from './gql';

import { ApproveInvoiceMutation, ApproveInvoiceMutationVariables, EContentProductDetailPosition, ECountryCode, EOfferVersionContentBlockLineItemSource, EOfferVersionContentBlockType, EOfferVersionStatus, EOfferVersionType, EProductLookupCode, GenerateClientInvoicesMutation, ViewInvoiceQuery, ViewWlOfferQuery, ViewWlOfferVersionQuery, WLInvoiceAttendanceInput, WLOfferListOutput, WLOfferListViewFragment, WLOfferVersionContentBlockLineItemInput, WLOfferVersionDiffOutputFragment, WLOfferVersionListOutput, WLOfferVersionListViewFragment } from '__generated__/graphql';

import { formatDate } from 'components/DateTime';

import { NO_PRICE } from '../../semshared/pricelist/quickprice';

import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';
import yup from 'validation';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatPrice } from 'components/Price';
import { calculateTotals, getFullEventDayRange } from 'semshared/offer/offer';
import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputCurrency } from 'components/form/FormInputCurrency';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormInputCountryDropdown, FormInputDropdown } from 'components/form/FormInputDropdown';
import FileUploadButton from 'components/FileUploadButton';
import { ProductTextSelector, ProductTextSelectorType } from 'semshared/pricelist/producttextselector';
import InformationDialog from 'components/dialogs/InformationDialog';
import { exportBase64ToFile, getFullBackendUrl } from 'helper/download';
import { formatPercentage } from 'components/Percentage';
import { EAM_EventProductLookupCodes, isValidAttendanceLookupCode, mapAttendanceDirectPay, mapAttendanceRegistered, mapAttendanceShow, mapLineItems2GuestCount, mapShowAttendance } from 'semshared/offer/invoice';
import { userSelector } from 'helper/security';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { formatOfferDay } from 'semshared/utils/format';
import { QuickPriceCalculatorLister, QuickPriceCalculatorTypeLister } from 'semshared/pricelist/quickprice_lister';

interface InvoiceProps {
  id: number;
}

interface InvoiceFormProps {
  offer: NonNullable<ViewInvoiceQuery['viewWlOffer']>,
  version: NonNullable<ViewInvoiceQuery['viewWlOfferInvoiceVersion']>,
  invoices: NonNullable<ViewInvoiceQuery['viewWlOfferGeneratedInvoices']>,
  event: ViewInvoiceQuery['viewWlOfferInvoiceEvent'],
  quickPriceCalculator: QuickPriceCalculatorTypeLister,
  productTexts: ProductTextSelectorType
}

const taxesSchema = yup.object().shape({
  taxRateId: yup.number().required(),
  name: yup.string().required(),
  rate: yup.number().required(),
  price: yup.number().required().notOneOf([NO_PRICE], `${i18next.t('invoice-err-noprice')}`),
});

const attendanceSchema = yup.object().shape({
  profileId: yup.number().required(),
  profileName: yup.string().required(),
  day: yup.number().required(),
  directPay: yup.boolean().required(),
  show: yup.boolean().required()
});

const componentsSchema = yup.object().shape({
  taxTypeId: yup.number().required(),
  name: yup.string().required(),
  price: yup.number().required().notOneOf([NO_PRICE], `${i18next.t('invoice-err-noprice')}`).label(i18next.t('invoice-lineitem-priceitem')),
});

const baseLineItemSchema = yup.object().shape({
  lineItemId: yup.number().required(),
  day: yup.number().required(),
  count: yup.number().required().label(i18next.t('invoice-lineitem-count')),
  sku: yup.string().nullable(),
  header: yup.string().required(),
  details: yup.string().nullable(),
  priceItem: yup.number().required().notOneOf([NO_PRICE], `${i18next.t('invoice-err-noprice')}`),
  priceGross: yup.number().required().notOneOf([NO_PRICE], `${i18next.t('invoice-err-noprice')}`),
  priceNet: yup.number().required().notOneOf([NO_PRICE], `${i18next.t('invoice-err-noprice')}`),
  components: yup.array().required().of(componentsSchema),
  taxes: yup.array().required().of(taxesSchema),
  media: yup.object().nullable().shape({
    id: yup.number().required(),
    name: yup.string().required(),
    mimeType: yup.string().required(),
    base64: yup.string().nullable()
  }),
  hasAttendances: yup.boolean().required(),
  attendances: yup.array().required().of(attendanceSchema),
});

const lineItemSchema = baseLineItemSchema.shape({
  isChanged: yup.boolean().required()
})

const daySchema = yup.object().shape({
  day: yup.number().required(),
  lineItems: yup.array().required().of(lineItemSchema),
  accepted: yup.boolean().required(),
  expanded: yup.boolean().required()
});

const extraLineItemSchema = baseLineItemSchema.shape({
  isDeleted: yup.boolean().required()
})

const cancellationSchema = yup.object().shape({
  daysToEvent: yup.number().required(),
  rate: yup.number().required(),
  lineItems: yup.array().required().of(lineItemSchema)
});

const addressSchema = yup.object().shape({
  businessName: yup.string().required().label(i18next.t('invoice-sender-name')),
  businessAddress1: yup.string().required().label(i18next.t('invoice-sender-address1')),
  businessAddress2: yup.string().nullable(),
  businessAddress3: yup.string().nullable(),
  businessAddress4: yup.string().nullable(),
  businessZip: yup.string().nullable().label(i18next.t('invoice-sender-zip')),
  businessCity: yup.string().nullable().label(i18next.t('invoice-sender-city')),
  businessCountry: yup.mixed<ECountryCode>().oneOf(Object.values(ECountryCode)).required().label(i18next.t('invoice-sender-country')),
  businessEmail: yup.string().nullable(),
  businessPhone: yup.string().nullable(),
  contactName: yup.string().nullable(),
  comment: yup.string().nullable(),
  accepted: yup.boolean().required(),
});

const validationSchema = yup.object().shape({
  days: yup.array().required().of(daySchema),
  extraLineItems: yup.array().required().of(extraLineItemSchema),
  cancellations: yup.array().required().of(cancellationSchema),
  agreedCancellationWaiveNet: yup.number().nullable(),
  manualCancellationWaiveNet: yup.number().nullable(),
  totalPriceNet: yup.number().required().notOneOf([NO_PRICE], `${i18next.t('invoice-err-noprice')}`),
  totalPriceGross: yup.number().required().notOneOf([NO_PRICE], `${i18next.t('invoice-err-noprice')}`),
  totalTaxes: yup.array().required().of(taxesSchema),
  sender: addressSchema.required()
});

export type InvoiceFormType = yup.InferType<typeof validationSchema>;
export type DayFormType = yup.InferType<typeof daySchema>;
export type LineItemFormType = yup.InferType<typeof lineItemSchema>;
export type ExtraLineItemFormType = yup.InferType<typeof extraLineItemSchema>;
export type ExtraMediaFormType = NonNullable<ExtraLineItemFormType['media']>;
export type AttendanceFormType = yup.InferType<typeof attendanceSchema>;
export type TaxesType = yup.InferType<typeof taxesSchema>;

interface InvoiceFormSubProps extends InvoiceFormProps {
}
interface InvoiceFormDayProps extends InvoiceFormSubProps {
  dayIndex: number;
  disabled: boolean;
  canEditItems: boolean;
  canAccept: boolean;
  isPricesNet: boolean;
  setHasAllAccepted: (a: boolean) => void;
  lineItemCountChanged: (dayIndex: number, lineItemIndex: number) => void;
  lineItemAttendanceChanged: (dayIndex: number, lineItemIndex: number, attendanceIndex: number) => void;
}
interface InvoiceFormLineItemProps extends InvoiceFormDayProps {
  lineItemIndex: number;
}
interface InvoiceFormLineItemAttendanceProps extends InvoiceFormLineItemProps {
  attendanceIndex: number;
}
interface InvoiceFormLineItemComponentProps extends InvoiceFormLineItemProps {
  lineItemComponentIndex: number;
}

function InvoiceFormDay(props: InvoiceFormDayProps) {
  const { dayIndex, version, disabled, canAccept } = props
  const { control, watch, setValue } = useFormContext<InvoiceFormType>();

  const dayWatch = watch(`days.${dayIndex}.day`);
  const dayDate = moment(version.startDate).add(dayWatch, 'days').toDate();

  const acceptedWatch = watch(`days.${dayIndex}.accepted`)

  useEffect(() => {
    if (!canAccept) return
    const daysWatch = watch('days')
    const na = daysWatch.find(d => !d.accepted)
    props.setHasAllAccepted(!na)
  }, [acceptedWatch])

  useEffect(() => {
    if (disabled) return
    if (!canAccept) return
    if (!acceptedWatch) return

    setValue(`days.${dayIndex}.expanded`, false)
    if (dayIndex < watch('days').length - 1) {
      setValue(`days.${dayIndex + 1}.expanded`, true)
    }
  }, [acceptedWatch])

  const { fields: lineItemsFields } = useFieldArray({
    control,
    name: `days.${dayIndex}.lineItems`,
  });

  return <Accordion expanded={watch(`days.${dayIndex}.expanded`)} onChange={(ev, expanded) => setValue(`days.${dayIndex}.expanded`, expanded)}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>
        {formatOfferDay(dayWatch!)} - {formatDate(dayDate)} ({lineItemsFields.length})
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={1}>
        {lineItemsFields.map((li, index) => (
          <InvoiceFormLineItem key={index} lineItemIndex={index} {...props} />
        ))}
      </Grid>
      {!disabled && canAccept &&
        <Grid container>
          <Grid item xs={12} justifyItems={'end'}>
            <FormInputCheckbox name={`days.${dayIndex}.accepted`} label={i18next.t('invoice-day-accepted')} control={control} />
          </Grid>
        </Grid>
      }
    </AccordionDetails>
  </Accordion>
}

function InvoiceFormLineItem(props: InvoiceFormLineItemProps) {
  const { dayIndex, lineItemIndex, version, disabled, canEditItems, isPricesNet } = props
  const { control, watch, setValue, formState: { defaultValues } } = useFormContext<InvoiceFormType>();

  const countWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.count`);
  const skuWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.sku`);
  const headerWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.header`);
  const detailsWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.details`);
  const hasAttendancesWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.hasAttendances`);
  const priceWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.priceItem`)
  const componentsWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.components`);

  const { fields: attendancesFields } = useFieldArray({
    control,
    name: `days.${dayIndex}.lineItems.${lineItemIndex}.attendances`,
  });
  const canEditAttendances = hasAttendancesWatch && attendancesFields.length > 0

  useEffect(() => {
    if (disabled || canEditAttendances) return
    if (!defaultValues || countWatch !== defaultValues.days![dayIndex]!.lineItems![lineItemIndex]!.count) {
      props.lineItemCountChanged(dayIndex, lineItemIndex)
    }
  }, [countWatch])

  useEffect(() => {
    if (disabled || !canEditItems) return
    if (componentsWatch.length > 1) return
    if (!defaultValues || priceWatch !== defaultValues.days![dayIndex]!.lineItems![lineItemIndex]!.priceItem) {
      props.lineItemCountChanged(dayIndex, lineItemIndex)
    }
  }, [componentsWatch, priceWatch])

  useEffect(() => {
    if (disabled || !canEditItems) return
    if (!defaultValues || headerWatch !== defaultValues.days![dayIndex]!.lineItems![lineItemIndex]!.header  || detailsWatch !== defaultValues.days![dayIndex]!.lineItems![lineItemIndex]!.details) {
      setValue(`days.${dayIndex}.lineItems.${lineItemIndex}.isChanged`, true)
    }
  }, [headerWatch, detailsWatch])

  const { fields: componentsFields } = useFieldArray({
    control,
    name: `days.${dayIndex}.lineItems.${lineItemIndex}.components`,
  });

  return <>
    <Grid item sm={1}>
      <FormInputNumber label={i18next.t('invoice-lineitem-count')} name={`days.${dayIndex}.lineItems.${lineItemIndex}.count`} control={control} disabled={disabled || canEditAttendances} />
    </Grid>
    <Grid item sm={7}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
          {watch(`days.${dayIndex}.lineItems.${lineItemIndex}.isChanged`) ? '*' : ''} {headerWatch} ({skuWatch})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {!disabled && canEditItems && <>
              <Grid item xs={12} sm={6}>
                <FormInputText label={i18next.t('invoice-lineitem-header')} name={`days.${dayIndex}.lineItems.${lineItemIndex}.header`} control={control} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInputText label={i18next.t('invoice-lineitem-details')}
                  name={`days.${dayIndex}.lineItems.${lineItemIndex}.details`}
                  control={control}
                  textFieldProps={{
                    multiline: true,
                    rows: 3,
                  }}
                />
              </Grid>
            </>}
            {canEditAttendances && attendancesFields.map((a, index) => (
              <InvoiceFormLineItemAttendance key={index} attendanceIndex={index} {...props} />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
    <Grid item sm={2}>
      {componentsWatch.length > 1 &&
        <Grid container>
          {componentsFields.map((c, index) => (<Grid item sm={12} key={index}>
            <InvoiceFormLineItemComponent lineItemComponentIndex={index} {...props} disabled={disabled || !canEditItems} />
          </Grid>))}
        </Grid>
      }
      {componentsWatch.length <= 1 &&
        <FormInputCurrency
          name={`days.${dayIndex}.lineItems.${lineItemIndex}.priceItem`}
          label={isPricesNet ? i18next.t('invoice-lineitem-price-net') : i18next.t('invoice-lineitem-price-gross')}
          currency={version.priceList.currency}
          emptyValue={NO_PRICE}
          control={control}
          disabled={disabled || !canEditItems}
        />
      }
    </Grid>
    <Grid item sm={1}>
      <FormInputCurrency name={`days.${dayIndex}.lineItems.${lineItemIndex}.priceNet`} emptyValue={NO_PRICE} label={i18next.t('invoice-lineitem-net')} currency={version.priceList.currency} control={control} disabled />
    </Grid>
    <Grid item sm={1}>
      <FormInputCurrency name={`days.${dayIndex}.lineItems.${lineItemIndex}.priceGross`} emptyValue={NO_PRICE} label={i18next.t('invoice-lineitem-gross')} currency={version.priceList.currency} control={control} disabled />
    </Grid>
  </>
}

function InvoiceFormLineItemAttendance({ dayIndex, lineItemIndex, attendanceIndex, disabled, lineItemAttendanceChanged }: InvoiceFormLineItemAttendanceProps) {
  const { control, watch } = useFormContext<InvoiceFormType>();

  const showWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.attendances.${attendanceIndex}.show`);

  useEffect(() => {
    if (disabled) return;
    lineItemAttendanceChanged(dayIndex, lineItemIndex, attendanceIndex)
  }, [showWatch])

  return <>
    <Grid item sm={6}>
      <FormInputText name={`days.${dayIndex}.lineItems.${lineItemIndex}.attendances.${attendanceIndex}.profileName`} control={control} disabled />
    </Grid>
    <Grid item sm={3}>
      <FormInputCheckbox name={`days.${dayIndex}.lineItems.${lineItemIndex}.attendances.${attendanceIndex}.show`} label={i18next.t('invoice-lineitem-attendance-show')} control={control} disabled={disabled} />
    </Grid>
    <Grid item sm={3}>
      <FormInputCheckbox name={`days.${dayIndex}.lineItems.${lineItemIndex}.attendances.${attendanceIndex}.directPay`} label={i18next.t('invoice-lineitem-attendance-directpay')} control={control} disabled />
    </Grid>
  </>
}

function InvoiceFormLineItemComponent(props: InvoiceFormLineItemComponentProps) {
  const { dayIndex, lineItemIndex, version, disabled, isPricesNet, canEditItems, lineItemComponentIndex } = props
  const { control, watch, formState: { defaultValues } } = useFormContext<InvoiceFormType>();

  const nameWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.components.${lineItemComponentIndex}.name`)
  const typeWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.components.${lineItemComponentIndex}.taxTypeId`)
  const priceWatch = watch(`days.${dayIndex}.lineItems.${lineItemIndex}.components.${lineItemComponentIndex}.price`)

  useEffect(() => {
    if (disabled || !canEditItems) return
    if (!defaultValues || priceWatch !== defaultValues.days![dayIndex]!.lineItems![lineItemIndex]!.components![lineItemComponentIndex]!.price) {
      props.lineItemCountChanged(dayIndex, lineItemIndex)
    }
  }, [priceWatch])

  const taxRate = props.quickPriceCalculator.getTaxRateForType(typeWatch);
  return (
    <FormInputCurrency
      name={`days.${dayIndex}.lineItems.${lineItemIndex}.components.${lineItemComponentIndex}.price`}
      label={`${taxRate ? taxRate.name : nameWatch} ${i18next.t(`invoice-lineitem-suffix-${isPricesNet ? 'net' : 'gross'}`)}`}
      currency={version.priceList.currency}
      control={control}
      emptyValue={NO_PRICE}
      required
      disabled={disabled}
    />
  );
}

interface InvoiceFormExtraLineItemProps extends InvoiceFormSubProps {
  extraLineItemIndex: number;
  disabled: boolean;
  isPricesNet: boolean;
  extraLineItemRemove: (index: number) => void;
}
interface InvoiceFormExtraLineItemComponentProps extends InvoiceFormExtraLineItemProps {
  extraLineItemComponentIndex: number;
}

function InvoiceFormExtraLineItemListener(props: { extraLineItemIndex: number, extraLineItemChanged: (index: number) => void }) {
  const { watch, control } = useFormContext<InvoiceFormType>();

  const countWatch = watch(`extraLineItems.${props.extraLineItemIndex}.count`)
  const priceWatch = watch(`extraLineItems.${props.extraLineItemIndex}.priceItem`)
  const dayWatch = watch(`extraLineItems.${props.extraLineItemIndex}.day`)

  const { fields: componentsFields } = useFieldArray({
    control,
    name: `extraLineItems.${props.extraLineItemIndex}.components`,
  });

  useEffect(() => {
    props.extraLineItemChanged(props.extraLineItemIndex)
  }, [countWatch, priceWatch, dayWatch])

  return <>
    {componentsFields.map((c, index) => <InvoiceFormExtraLineItemComponentListener key={index} extraLineItemComponentIndex={index} {...props} />)}
  </>
}
function InvoiceFormExtraLineItemComponentListener(props: { extraLineItemIndex: number, extraLineItemComponentIndex: number, extraLineItemChanged: (index: number) => void }) {
  const { watch } = useFormContext<InvoiceFormType>();

  const priceWatch = watch(`extraLineItems.${props.extraLineItemIndex}.components.${props.extraLineItemComponentIndex}.price`)
  useEffect(() => {
    props.extraLineItemChanged(props.extraLineItemIndex)
  }, [priceWatch])
  return null
}

function InvoiceFormExtraLineItem(props: InvoiceFormExtraLineItemProps) {
  const { extraLineItemIndex, version, disabled, isPricesNet, extraLineItemRemove } = props
  const { control, watch, setValue } = useFormContext<InvoiceFormType>();

  const [mediaQuery, { loading: mediaLoading }] = useLazyQuery(INVOICE_VIEW_MEDIA_QUERY);

  const [mediaBase64, setMediaBase64] = useState<string>()

  const daysWatch = watch('days')
  const isDeletedWatch = watch(`extraLineItems.${extraLineItemIndex}.isDeleted`);
  const mediaWatch = watch(`extraLineItems.${extraLineItemIndex}.media`);
  const componentsWatch = watch(`extraLineItems.${extraLineItemIndex}.components`);

  const { fields: componentsFields } = useFieldArray({
    control,
    name: `extraLineItems.${extraLineItemIndex}.components`,
  });

  if (isDeletedWatch) return null
  else return <>
    <Grid item sm={2}>
      <FormInputDropdown
        name={`extraLineItems.${extraLineItemIndex}.day`}
        label={i18next.t('invoice-lineitem-day')}
        control={control}
        options={_.uniq([-1, ...daysWatch.map(d => d.day)]).sort().map(day => ({
          value: day,
          label: <>{formatOfferDay(day)} - {formatDate(moment(props.version.startDate).add(day, 'days').toDate())}</>
        }))}
        required
        disabled={disabled}
      />
    </Grid>
    <Grid item sm={1}>
      <FormInputNumber label={i18next.t('invoice-lineitem-count')} name={`extraLineItems.${extraLineItemIndex}.count`} control={control} disabled={disabled} />
    </Grid>
    <Grid item sm={1}>
      <FormInputText label={i18next.t('invoice-lineitem-sku')} name={`extraLineItems.${extraLineItemIndex}.sku`} control={control} disabled />
    </Grid>
    <Grid item sm={3}>
      <FormInputText label={i18next.t('invoice-lineitem-header')} name={`extraLineItems.${extraLineItemIndex}.header`} control={control} disabled={disabled} />
    </Grid>
    {componentsWatch.length > 1 &&
      <Grid item sm={2}>
        <Grid container>
          {componentsFields.map((c, index) => (<Grid item sm={12} key={index}>
            <InvoiceFormExtraLineItemComponent extraLineItemComponentIndex={index} {...props} disabled={disabled} />
          </Grid>))}
        </Grid>
      </Grid>
    }
    {componentsWatch.length <= 1 &&
      <Grid item sm={2}>
        <FormInputCurrency
          name={`extraLineItems.${extraLineItemIndex}.priceItem`}
          label={isPricesNet ? i18next.t('invoice-lineitem-price-net') : i18next.t('invoice-lineitem-price-gross')}
          emptyValue={NO_PRICE}
          currency={version.priceList.currency}
          control={control}
          disabled={disabled}
        />
      </Grid>
    }
    <Grid item sm={1}>
      <FormInputCurrency name={`extraLineItems.${extraLineItemIndex}.priceNet`} emptyValue={NO_PRICE} label={i18next.t('invoice-lineitem-net')} currency={version.priceList.currency} control={control} disabled />
    </Grid>
    <Grid item sm={1}>
      <FormInputCurrency name={`extraLineItems.${extraLineItemIndex}.priceGross`} emptyValue={NO_PRICE} label={i18next.t('invoice-lineitem-gross')} currency={version.priceList.currency} control={control} disabled />
    </Grid>
    <Grid item sm={1}>
      <FileUploadButton
        name={`uploadMedia_${extraLineItemIndex}`}
        accept={'image/*, application/pdf'}
        disabled={disabled}
        startIcon={<UploadIcon />}
        binary={(name, type) => true}
        onChange={async (name, mimeType, base64) => {
          setValue(`extraLineItems.${extraLineItemIndex}.media`, { id: 0, name, mimeType, base64 })
        }}
      />
      <IconButton
        disabled={!mediaWatch}
        onClick={() => {
          if (!mediaWatch) return
          if (mediaWatch.base64) {
            if (mediaWatch.mimeType.startsWith('image/')) {
              setMediaBase64(mediaWatch.base64)
            } else {
              exportBase64ToFile(mediaWatch.base64, mediaWatch.mimeType, mediaWatch.name)
            }
          } else {
            mediaQuery({
              variables: {
                mediaId: mediaWatch.id
              }
            }).then(res => {
              const base64 = res.data?.readWlOfferInvoiceMediaBase64
              if (base64) {
                if (mediaWatch.mimeType.startsWith('image/')) {
                  setMediaBase64(base64)
                } else {
                  exportBase64ToFile(base64, mediaWatch.mimeType, mediaWatch.name)
                }
              }
            })
          }
        }}
      >
        <AttachmentIcon />
      </IconButton>
      {!disabled && <IconButton
        onClick={() => {
          extraLineItemRemove(extraLineItemIndex);
        }}
      >
        <DeleteIcon />
      </IconButton>}
    </Grid>
    {mediaWatch && <InformationDialog
      title={mediaWatch.name}
      open={!!mediaBase64}
      onConfirm={() => setMediaBase64(undefined)}
      maxWidth="md"
      fullWidth
    >
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            {mediaBase64 && <img width="100%" src={`data:${mediaWatch.mimeType};base64,${mediaBase64}`} />}
          </Grid>
        </Grid>
      </Box>
    </InformationDialog>}
  </>
}

function InvoiceFormExtraLineItemComponent(props: InvoiceFormExtraLineItemComponentProps) {
  const { extraLineItemIndex, version, disabled, isPricesNet, extraLineItemComponentIndex } = props
  const { control, watch } = useFormContext<InvoiceFormType>();

  const nameWatch = watch(`extraLineItems.${extraLineItemIndex}.components.${extraLineItemComponentIndex}.name`)
  const typeWatch = watch(`extraLineItems.${extraLineItemIndex}.components.${extraLineItemComponentIndex}.taxTypeId`)

  const taxRate = props.quickPriceCalculator.getTaxRateForType(typeWatch);
  return (
    <FormInputCurrency
      name={`extraLineItems.${extraLineItemIndex}.components.${extraLineItemComponentIndex}.price`}
      label={`${taxRate ? taxRate.name : nameWatch} ${i18next.t(`invoice-lineitem-suffix-${isPricesNet ? 'net' : 'gross'}`)}`}
      currency={version.priceList.currency}
      control={control}
      emptyValue={NO_PRICE}
      required
      disabled={disabled}
    />
  );
}

interface InvoiceFormCancellationProps extends InvoiceFormProps {
  ruleIndex: number;
  disabled: boolean;
}
interface InvoiceFormCancellationLineItemProps extends InvoiceFormCancellationProps {
  lineItemIndex: number;
}

function InvoiceFormCancellation(props: InvoiceFormCancellationProps) {
  const { ruleIndex, version, disabled } = props
  const { control, watch, getValues, setValue } = useFormContext<InvoiceFormType>();

  const phaseWatch = watch(`cancellations.${ruleIndex}.rate`);
  const daysToEventWatch = watch(`cancellations.${ruleIndex}.daysToEvent`);

  const { fields: lineItemsFields } = useFieldArray({
    control,
    name: `cancellations.${ruleIndex}.lineItems`,
  });

  return <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>
        Stornophase {formatPercentage(phaseWatch, 0)} (bis {daysToEventWatch} Tage)
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container>
        {lineItemsFields.map((li, index) => (
          <InvoiceFormCancellationLineItem key={index} lineItemIndex={index} {...props} />
        ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
}

function InvoiceFormCancellationLineItem(props: InvoiceFormCancellationLineItemProps) {
  const { ruleIndex, lineItemIndex, version } = props
  const { control, watch, getValues } = useFormContext<InvoiceFormType>();

  const dayWatch = watch(`cancellations.${ruleIndex}.lineItems.${lineItemIndex}.day`);
  const skuWatch = watch(`cancellations.${ruleIndex}.lineItems.${lineItemIndex}.sku`);
  const headerWatch = watch(`cancellations.${ruleIndex}.lineItems.${lineItemIndex}.header`);

  const dayDate = moment(version.startDate).add(dayWatch, 'days').toDate();

  return <>
    <Grid item sm={2}>
      {formatOfferDay(dayWatch!)} - {formatDate(dayDate)}
    </Grid>
    <Grid item sm={1}>
      <FormInputNumber name={`cancellations.${ruleIndex}.lineItems.${lineItemIndex}.count`} control={control} disabled />
    </Grid>
    <Grid item sm={8}>
      <Typography>
        {headerWatch} ({skuWatch})
      </Typography>
    </Grid>
    <Grid item sm={1}>
      <FormInputCurrency name={`cancellations.${ruleIndex}.lineItems.${lineItemIndex}.priceGross`} emptyValue={NO_PRICE} currency={version.priceList.currency} control={control} disabled />
    </Grid>
  </>
}

function InvoiceForm(props: InvoiceFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = userSelector()!;

  const [approveInvoice, { loading: approveInvoiceLoading }] = useMutation(INVOICE_APPROVE_MUTATION);
  const [rejectInvoiceSEM, { loading: rejectInvoiceSEMLoading }] = useMutation(INVOICE_REJECT_SEM_MUTATION);
  const [generateClientInvoices, { loading: generateClientInvoicesLoading }] = useMutation(INVOICE_GENERATE_MUTATION);
  const [listInvoiceCostCentres, { data: listInvoiceCentresData, loading: listInvoiceCostCentresLoading }] = useLazyQuery(LIST_INVOICE_COSTCENTRES_QUERY, { variables: { offerId: props.offer.id }, fetchPolicy: 'network-only' })

  const [selectedTab, setSelectedTab] = useState(0);
  const [offer] = useState(props.offer);
  const [version] = useState(props.version);
  const [event] = useState(props.event);

  const [hasAllDaysAccepted, setHasAllDaysAccepted] = useState(false);
  const [hasSenderAccepted, setHasSenderAccepted] = useState(false);

  const [showGenerateInvoicesDialog, setShowGenerateInvoicesDialog] = useState(false);
  const [selectedCostCentres, setSelectedCostCentres] = useState<{ costCentre: string, name: string, selected: boolean }[]>([]);

  const [approvedId, setApprovedId] = useState(0);
  useEffect(() => {
    if (approvedId > 0) navigate(`/invoices/${offer.id}/${approvedId}`);
  }, [approvedId]);

  const [canEdit] = useState(user.isSeminargo ? (version.status === EOfferVersionStatus.IV_WAITFORSEMAPPROVAL || version.status === EOfferVersionStatus.IV_WAITFORHOTEL) : version.status === EOfferVersionStatus.IV_WAITFORHOTEL)
  const [canEditItems] = useState(user.isSeminargo && version.status === EOfferVersionStatus.IV_WAITFORSEMAPPROVAL)
  const [canApprove] = useState(version.status === EOfferVersionStatus.IV_WAITFORHOTEL)
  const [canSEMApprove] = useState(user.isSeminargo && version.status === EOfferVersionStatus.IV_WAITFORSEMAPPROVAL)
  const [priceList] = useState(props.quickPriceCalculator.getPriceList({ date: new Date(), priceListId: version.priceList.id }))

  const [generatedInvoices, setGeneratedInvoices] = useState<GenerateClientInvoicesMutation['generateClientInvoices']>(props.invoices)
  const [showGeneratedInvoiceId, setShowGeneratedInvoiceId] = useState<number | undefined>(props.invoices.length > 0 ? props.invoices[0].id : undefined)

  const eventDays = getFullEventDayRange(version.contentBlocks)

  const toFormSchema = (offer: typeof props.offer, version: typeof props.version, event: typeof props.event): InvoiceFormType => {

    const _getLookupCode = (dayIndex: number, lineItemIndex: number): EProductLookupCode | null => {
      const sku = version.contentBlocks[dayIndex].lineItems[lineItemIndex].sku
      if (!sku) return null

      const lookupCode = props.quickPriceCalculator.allProductsDb!.find(p => p.sku === sku)?.lookupCode || props.quickPriceCalculator.allBundlesDb!.find(b => b.sku === sku)?.lookupCode
      if (!lookupCode) return null

      return lookupCode as EProductLookupCode
    }

    const _hasAttendances = (dayIndex: number, lineItemIndex: number): boolean => {
      const lookupCode = _getLookupCode(dayIndex, lineItemIndex)
      if (!lookupCode) return false

      return isValidAttendanceLookupCode(lookupCode)
    }
    const _createAttendances = (dayIndex: number, lineItemIndex: number): AttendanceFormType[] => {
      if (!event || event.attendances.length === 0) return []
      if (!_hasAttendances(dayIndex, lineItemIndex)) return []

      const lookupCode = _getLookupCode(dayIndex, lineItemIndex)!
      const lineItem = version.contentBlocks[dayIndex].lineItems[lineItemIndex]

      const attendances = event!.attendances.filter(a => a.days.findIndex(d => d.day === lineItem.day) >= 0).map(a => {
        const aDay = a.days.find(d => d.day === lineItem.day)!

        if (mapAttendanceRegistered(lookupCode as EAM_EventProductLookupCodes, aDay)) {
          return {
            profileId: a.profile.id,
            profileName: a.profile.lastname + ' ' + a.profile.firstname,
            day: lineItem.day,
            directPay: mapAttendanceDirectPay(lookupCode as EAM_EventProductLookupCodes, event!, a, aDay),
            show: mapAttendanceShow(lookupCode as EAM_EventProductLookupCodes, aDay),
          }
        }
      })
      return attendances.filter(a => a).map(a => a!)
    }

    let initDayCounter = 0

    return {
      extraLineItems: version.contentBlocks.reduce<ExtraLineItemFormType[]>((agg, cb, cbIndex) => {
        if (cb.type === EOfferVersionContentBlockType.LINE_ITEMS && cb.lineItems.length > 0) {
          for (const li of cb.lineItems.filter(li => li.source === EOfferVersionContentBlockLineItemSource.EXTRA)) {
            agg.push({
              lineItemId: li.id,
              day: li.day,
              count: li.count,
              sku: li.sku,
              header: li.header,
              details: li.details,
              priceItem: li.priceItem,
              priceGross: li.priceGross,
              priceNet: li.priceNet,
              components: li.components.map(c => ({
                taxTypeId: c.type.id,
                name: c.type.name,
                price: c.price,
              })),
              taxes: li.taxes.map(t => ({
                taxRateId: t.rate.id,
                name: t.type.name,
                rate: t.rate.rate,
                price: t.price,
              })),
              media: li.media ? {
                id: li.media.id,
                name: li.media!.name,
                mimeType: li.media!.mimeType
              } : null,
              hasAttendances: false,
              attendances: [],
              isDeleted: false
            })
          }
        }
        return [...agg]
      }, []),
      days: eventDays.map(day => ({
        day: day,
        accepted: false,
        expanded: 0 === initDayCounter++,
        lineItems: version.contentBlocks.reduce<(typeof version['contentBlocks'][0]['lineItems'][0] & { cbIndex: number })[]>((agg, cb, cbIndex) => [...agg, ...(cb.type === EOfferVersionContentBlockType.LINE_ITEMS ? cb.lineItems.filter(li => li.day === day) : []).map(li => ({ ...li, cbIndex }))], []).map((li, liIndex) => li.source === EOfferVersionContentBlockLineItemSource.OFFER ? ({
          lineItemId: li.id,
          day: li.day,
          count: li.count,
          sku: li.sku,
          header: li.header,
          details: li.details,
          priceItem: li.priceItem,
          priceGross: li.priceGross,
          priceNet: li.priceNet,
          components: li.components.map(c => ({
            taxTypeId: c.type.id,
            name: c.type.name,
            price: c.price,
          })),
          taxes: li.taxes.map(t => ({
            taxRateId: t.rate.id,
            name: t.type.name,
            rate: t.rate.rate,
            price: t.price,
          })),
          media: null,
          hasAttendances: _hasAttendances(li.cbIndex, liIndex),
          attendances: _createAttendances(li.cbIndex, liIndex),
          isChanged: false
        }) : null).filter(li => li).map(li => li!),
      })),
      cancellations: version.contentBlocks.map((cb, cbIndex) => (cb.type === EOfferVersionContentBlockType.CANCELLATION_ITEMS && cb.lineItems.length > 0) ? ({
        daysToEvent: cb.cancellationRule!.daysToEvent,
        rate: cb.cancellationRule!.rate,
        lineItems: cb.lineItems.map((li, liIndex) => li.source === EOfferVersionContentBlockLineItemSource.OFFER ? ({
          lineItemId: li.id,
          day: li.day,
          count: li.count,
          sku: li.sku,
          header: li.header,
          details: li.details,
          priceItem: li.priceItem,
          priceGross: li.priceGross,
          priceNet: li.priceNet,
          components: li.components.map(c => ({
            taxTypeId: c.type.id,
            name: c.type.name,
            price: c.price,
          })),
          taxes: li.taxes.map(t => ({
            taxRateId: t.rate.id,
            name: t.type.name,
            rate: t.rate.rate,
            price: t.price,
          })),
          media: null,
          hasAttendances: false,
          attendances: [],
          isChanged: false
        }) : null).filter(li => li).map(li => li!),
      }) : null).filter(cb => cb).map(cb => cb!),
      agreedCancellationWaiveNet: version.agreedCancellationWaiveNet,
      manualCancellationWaiveNet: version.manualCancellationWaiveNet,
      totalPriceNet: version.totalPriceNet,
      totalPriceGross: version.totalPriceGross,
      totalTaxes: version.taxes.map(t => ({
        taxRateId: t.rate.id,
        name: t.type.name,
        rate: t.rate.rate,
        price: t.price,
      })),
      sender: {
        businessName: '',
        businessAddress1: '',
        businessCity: '',
        businessZip: '',
        businessCountry: ECountryCode.AT,
        accepted: false,
        ...(version.sender || {})
      },
    }
  }
  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema) as any,
    defaultValues: toFormSchema(props.offer, props.version, props.event),
  });
  const {
    handleSubmit,
    control,
    trigger,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors: validationErrors, isValid, isDirty, isValidating, isSubmitting, defaultValues },
  } = form;

  console.log(defaultValues)

  const { fields: daysFields } = useFieldArray({
    control,
    name: 'days',
  });

  const { fields: extraLineItemsFields, append: extraLineItemAppend, remove: extraLineItemRemove } = useFieldArray({
    control,
    name: 'extraLineItems',
  });

  const { fields: cancellationsFields } = useFieldArray({
    control,
    name: 'cancellations',
  });

  const senderAcceptedWatch = watch('sender.accepted')

  useEffect(() => {
    setHasSenderAccepted(senderAcceptedWatch)
  }, [senderAcceptedWatch])

  const manualCancellationWaiveNetWatch = watch('manualCancellationWaiveNet')
  useEffect(() => {
    onTotalsChanged()
  }, [manualCancellationWaiveNetWatch])

  const lineItemCountChanged = (dayIndex: number, lineItemIndex: number) => {
    const lineItem = getValues(`days.${dayIndex}.lineItems.${lineItemIndex}`);
    const lineItemDate = moment(version.startDate).add(lineItem.day, 'days').toDate();

    try {
      const priceItem = lineItem.components.length > 1 ? lineItem.components.reduce((s, c) => s + c.price, 0) : lineItem.priceItem;
      const calcLineItem = props.quickPriceCalculator.getSKULineItem(lineItem.sku!, { date: lineItemDate, itemCount: lineItem.count }, {
        price: priceItem,
        bundlePriceFromProduct: false,
        components: lineItem.components.map(c => ({
          taxTypeId: c.taxTypeId,
          price: c.price,
        })),
      });
      if (!calcLineItem) return

      //console.log('lineItemChanged', guestCounts, calcLineItem)
      setValue(`days.${dayIndex}.lineItems.${lineItemIndex}.priceItem`, priceItem);
      setValue(`days.${dayIndex}.lineItems.${lineItemIndex}.isChanged`, true);
      setValue(`days.${dayIndex}.lineItems.${lineItemIndex}.priceGross`, calcLineItem.priceGross );
      setValue(`days.${dayIndex}.lineItems.${lineItemIndex}.priceNet`, calcLineItem.priceNet);
      setValue(
        `days.${dayIndex}.lineItems.${lineItemIndex}.components`,
        calcLineItem.components.map(c => ({
          taxTypeId: c.type.id,
          name: c.type.name,
          price: c.price,
        })),
      );
      setValue(
        `days.${dayIndex}.lineItems.${lineItemIndex}.taxes`,
        calcLineItem.taxes.map(t => ({
          taxRateId: t.rate.id,
          name: t.type.name,
          rate: t.rate.rate,
          price: t.price,
        })),
      );
      setTimeout(() => onTotalsChanged());
    } catch (err) {
      dispatchException(dispatch, err);
    }
  }
  const lineItemAttendanceChanged = (dayIndex: number, lineItemIndex: number, attendanceIndex: number) => {
    const attendanceItems = getValues(`days.${dayIndex}.lineItems.${lineItemIndex}.attendances`);
    const itemCount = attendanceItems.filter(a => !a.directPay || (a.directPay && !a.show)).length;
    const lineItemCount = getValues(`days.${dayIndex}.lineItems.${lineItemIndex}.count`);
    if (lineItemCount !== itemCount) {
      setValue(`days.${dayIndex}.lineItems.${lineItemIndex}.count`, itemCount);
      setTimeout(() => lineItemCountChanged(dayIndex, lineItemIndex), 0);
    }
  }

  const extraLineItemAdd = (sku: string, media?: ExtraMediaFormType) => {
    try {
      const header = props.productTexts.getProductText({
        sku: sku,
        hotelId: offer.hotel.id,
        position: EContentProductDetailPosition.HEADER,
        language: offer.language
      });
      const details = props.productTexts.getProductText({
        sku: sku,
        hotelId: offer.hotel.id,
        position: EContentProductDetailPosition.DETAILS,
        language: offer.language
      });

      //TODO Guest Count
      const calcLineItem = props.quickPriceCalculator.getSKULineItem(sku, { date: version.startDate, itemCount: 1 }, null, { allowNoPrices: true });
      if (calcLineItem) {
        extraLineItemAppend({
          lineItemId: 0,
          sku: calcLineItem.sku,
          count: calcLineItem.count,
          priceItem: calcLineItem.priceItem,
          priceGross: calcLineItem.priceGross,
          priceNet: calcLineItem.priceNet,
          day: 0,
          header: header || calcLineItem.name,
          details: details || '',
          media: media || null,
          hasAttendances: false,
          attendances: [],
          components: calcLineItem.components.map(c => ({
            taxTypeId: c.type.id,
            name: c.type.name,
            price: c.price,
          })),
          taxes: calcLineItem.taxes.map(t => ({
            taxRateId: t.rate.id,
            name: t.type.name,
            rate: t.rate.rate,
            price: t.price,
          })),
          isDeleted: false
        });
        setTimeout(() => onTotalsChanged(), 0);
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  }
  const extraLineItemChanged = (eliIndex: number) => {
    const lineItem = getValues(`extraLineItems.${eliIndex}`);
    const lineItemDate = moment(version.startDate).add(lineItem.day, 'days').toDate();

    try {
      const priceItem = lineItem.components.length > 1 ? lineItem.components.reduce((s, c) => s + c.price, 0) : lineItem.priceItem;

      const calcLineItem = props.quickPriceCalculator.getSKULineItem(lineItem.sku!, { date: lineItemDate, itemCount: lineItem.count }, {
        price: priceItem,
        bundlePriceFromProduct: false,
        components: lineItem.components.map(c => ({
          taxTypeId: c.taxTypeId,
          price: c.price,
        })),
      });
      if (!calcLineItem) return

      setValue(`extraLineItems.${eliIndex}.priceItem`, priceItem);
      setValue(`extraLineItems.${eliIndex}.priceGross`, calcLineItem.priceGross);
      setValue(`extraLineItems.${eliIndex}.priceNet`, calcLineItem.priceNet);
      setValue(
        `extraLineItems.${eliIndex}.components`,
        calcLineItem.components.map(c => ({
          taxTypeId: c.type.id,
          name: c.type.name,
          price: c.price,
        })),
      );
      setValue(
        `extraLineItems.${eliIndex}.taxes`,
        calcLineItem.taxes.map(t => ({
          taxRateId: t.rate.id,
          name: t.type.name,
          rate: t.rate.rate,
          price: t.price,
        })),
      );
      setTimeout(() => onTotalsChanged(), 0);
    } catch (err) {
      dispatchException(dispatch, err);
    }
  }
  const onTotalsChanged = () => {
    const { totalPriceNet, totalPriceGross, totalTaxes } = calculateTotals({
      agreedCancellationWaiveNet: getValues('agreedCancellationWaiveNet'),
      manualCancellationWaiveNet: getValues('manualCancellationWaiveNet'),
      contentBlocks: [
        ...getValues('days').map(d => ({ type: EOfferVersionContentBlockType.LINE_ITEMS, lineItems: d.lineItems })),
        {
          type: EOfferVersionContentBlockType.LINE_ITEMS,
          lineItems: getValues('extraLineItems').filter(li => !li.isDeleted)
        },
        ...getValues('cancellations').map(d => ({ type: EOfferVersionContentBlockType.CANCELLATION_ITEMS, lineItems: d.lineItems })),
      ]
    })
    setValue('totalPriceNet', totalPriceNet);
    setValue('totalPriceGross', totalPriceGross);
    setValue('totalTaxes', _.orderBy(totalTaxes, [t => t.name], 'asc'));
    trigger();
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmitApproveHotel = async (values: InvoiceFormType) => onSubmit(values, true, false, false, false)
  const onSubmitCancelHotel = async (values: InvoiceFormType) => onSubmit(values, false, true, false, false)
  const onSubmitApproveSEM = async (values: InvoiceFormType) => onSubmit(values, false, false, true, false)
  const onSubmitRejectSEM = async (values: InvoiceFormType) => onSubmit(values, false, false, false, true)

  const onSubmit = async (values: InvoiceFormType, approveAsHotel: boolean, cancelAsHotel: boolean, approveAsSEM: boolean, rejectAsSEM: boolean) => {
    if (!canEdit) return
    if (approveAsHotel && !canApprove) return
    if (cancelAsHotel && !canApprove) return
    if (approveAsSEM && !canSEMApprove) return
    if (rejectAsSEM && !canSEMApprove) return

    const attendances: WLInvoiceAttendanceInput[] = []
    const _get_a = (day: number, profileId: number) => {
      let r = attendances.find(a => a.day === day && a.profileId === profileId)
      if (!r) {
        r = {
          day: day,
          profileId: profileId
        }
        attendances.push(r)
      }
      return r
    }

    for (const day of values.days) {
      for (const li of day.lineItems.filter(li => li.hasAttendances)) {
        if (!li.sku) continue

        const lookupCode = props.quickPriceCalculator.allProductsDb!.find(p => p.sku === li.sku)?.lookupCode || props.quickPriceCalculator.allBundlesDb!.find(b => b.sku === li.sku)?.lookupCode
        if (!lookupCode) continue

        mapShowAttendance(lookupCode as EAM_EventProductLookupCodes, li.attendances.map(a => a.show), li.attendances.map(a => _get_a(day.day, a.profileId)))
      }
    }
    try {
      const res = await approveInvoice({
        variables: {
          offerVersionId: props.version.id,
          attendances: attendances,
          replaceLineItems: values.days.reduce((agg, day) => [
            ...agg,
            ...day.lineItems.filter(li => li.isChanged).map(eli => ({
              day: eli.day,
              sku: eli.sku,
              header: eli.header,
              details: eli.details || '',
              count: eli.count,
              sequence: 0,
              priceItem: eli.priceItem,
              priceNet: eli.priceNet,
              priceGross: eli.priceGross,
              components: eli.components.map(elic => ({
                taxTypeId: elic.taxTypeId,
                price: elic.price
              })),
              taxes: eli.taxes.map(elit => ({
                taxRateId: elit.taxRateId,
                price: elit.price
              })),
            }))
          ], [] as WLOfferVersionContentBlockLineItemInput[]),
          extraLineItems: values.extraLineItems.filter(eli => !eli.isDeleted).map(eli => ({
            day: eli.day,
            sku: eli.sku,
            header: eli.header,
            details: eli.details || '',
            count: eli.count,
            sequence: 0,
            priceItem: eli.priceItem,
            priceNet: eli.priceNet,
            priceGross: eli.priceGross,
            components: eli.components.map(elic => ({
              taxTypeId: elic.taxTypeId,
              price: elic.price
            })),
            taxes: eli.taxes.map(elit => ({
              taxRateId: elit.taxRateId,
              price: elit.price
            })),
            ...(eli.media && eli.media.id && !eli.media.base64 ? { mediaId: eli.media.id } : {}),
            media: eli.media && eli.media.base64 ? {
              name: eli.media.name,
              mimeType: eli.media.mimeType,
              base64: eli.media.base64
            } : undefined
          })),
          deleteLineItemIds: values.extraLineItems.filter(eli => eli.lineItemId > 0 && eli.isDeleted).map(eli => eli.lineItemId),
          sender: {
            businessName: values.sender.businessName,
            businessAddress1: values.sender.businessAddress1,
            businessAddress2: values.sender.businessAddress2,
            businessAddress3: values.sender.businessAddress3,
            businessAddress4: values.sender.businessAddress4,
            businessZip: values.sender.businessZip,
            businessCity: values.sender.businessCity,
            businessCountry: values.sender.businessCountry,
            businessEmail: values.sender.businessEmail,
            businessPhone: values.sender.businessPhone,
            comment: values.sender.comment,
            contactName: values.sender.contactName
          },
          manualCancellationWaiveNet: values.manualCancellationWaiveNet,
          approveAsHotel: approveAsHotel,
          cancelAsHotel: cancelAsHotel,
          approveAsSEM: approveAsSEM,
          rejectAsSEM: rejectAsSEM
        },
        update: cache => {
          EVICT_INVOICE_QUERIES(cache);
        },
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_INVOICE_QUERIES(props.offer.id, props.version.id),
      });
      reset(toFormSchema(offer, res.data!.approveInvoice, event));
      setApprovedId(res.data!.approveInvoice.id);
      if (approveAsHotel) dispatchMessage(dispatch, i18next.t('invoice-status-approved'));
      else if (cancelAsHotel) dispatchMessage(dispatch, i18next.t('invoice-status-cancelled'));
      else if (approveAsSEM) dispatchMessage(dispatch, i18next.t('invoice-status-semconfirmed'));
      else if (rejectAsSEM) dispatchMessage(dispatch, i18next.t('invoice-status-semrejected'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t(`invoices-list-page-title-${props.offer.source}`), props.offer])}
        </title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {props.offer.refCode} - {i18next.t(`enums-EOfferVersionStatus-${props.version.status}`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {canApprove && watch('totalPriceGross') !== 0 &&
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              confirmationQuestion={i18next.t('invoices-confirm-question')}
              confirmationTitle={i18next.t('invoices-confirm-question-title')}
              doubleConfirmationQuestion={!hasAllDaysAccepted || !hasSenderAccepted ? i18next.t('invoices-confirm-question-force') : undefined}
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <ThumbUpIcon />}
              disabled={!isValid || isSubmitting}
              onConfirm={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmitApproveHotel)();
                }
              }}
            >
              {i18next.t('invoices-confirm-title')}
            </ConfirmationButton>
          }
          {canApprove && watch('totalPriceGross') === 0 &&
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              confirmationQuestion={i18next.t('invoices-cancel-question')}
              confirmationTitle={i18next.t('invoices-cancel-question-title')}
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <CancelIcon />}
              disabled={!isValid || isSubmitting}
              onConfirm={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmitCancelHotel)();
                }
              }}
            >
              {i18next.t('invoices-cancel-title')}
            </ConfirmationButton>
          }
          {canSEMApprove && <>
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              confirmationQuestion={i18next.t('invoices-semconfirm-question')}
              confirmationTitle={i18next.t('invoices-semconfirm-question-title')}
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <ThumbUpIcon />}
              disabled={!isValid || isSubmitting}
              onConfirm={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmitApproveSEM)();
                }
              }}
            >
              {i18next.t('invoices-semconfirm-title')}
            </ConfirmationButton>
            <ConfirmationButton
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              confirmationQuestion={i18next.t('invoices-semreject-question')}
              confirmationTitle={i18next.t('invoices-semreject-question-title')}
              startIcon={isSubmitting ? <CircularProgress size={24} /> : <ThumbDownIcon />}
              disabled={!isValid || isSubmitting}
              onConfirm={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmitRejectSEM)();
                }
              }}
            >
              {i18next.t('invoices-semreject-title')}
            </ConfirmationButton>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              color="secondary"
              startIcon={listInvoiceCostCentresLoading || generateClientInvoicesLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
              onClick={async () => {
                const res = await listInvoiceCostCentres()
                setSelectedCostCentres((res.data?.listInvoiceCostCentres || []).map(l => ({
                  costCentre: l.costCentre,
                  name: l.name,
                  selected: true
                })))
                setShowGenerateInvoicesDialog(true)
              }}
            >
              {i18next.t('invoices-generate-client-invoices')}
            </Button>
            <ConfirmationDialog
              title={i18next.t('invoices-generate-client-invoices')}
              open={showGenerateInvoicesDialog}
              setOpen={setShowGenerateInvoicesDialog}
              keepOpenOnConfirm={true}
              onConfirm={async () => {
                setShowGenerateInvoicesDialog(false);
                try {
                  const { data } = await generateClientInvoices({
                    variables: {
                      offerVersionId: version.id,
                      costCentres: selectedCostCentres.filter(s => s.selected).map(s => s.costCentre)
                    },
                    update: cache => {
                      EVICT_INVOICE_QUERIES(cache);
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: REFETCH_INVOICE_QUERIES(props.offer.id, props.version.id)
                  })
                  if (data?.generateClientInvoices) {
                    setGeneratedInvoices(data!.generateClientInvoices)
                    if (data!.generateClientInvoices.length > 0) {
                      setShowGeneratedInvoiceId(data!.generateClientInvoices[0].id)
                    }
                    setSelectedTab(2)
                  }
                } catch (err) {
                  dispatchException(dispatch, err);
                }
              }}
            >
              <Grid container spacing={2}>
                {selectedCostCentres.length === 0 && <Grid item xs={12}>
                  {i18next.t('invoices-generate-client-invoices-no-costcentres')}
                </Grid>}
                {selectedCostCentres.length > 0 && <>
                  <Grid item xs={12}>
                    {i18next.t('invoices-generate-client-invoices-split-costcentres')}
                  </Grid>
                  {selectedCostCentres.map((cc, i) => <Grid key={i} item xs={12}>
                    <Checkbox
                      checked={cc.selected}
                      onChange={() => {
                        const cc = [...selectedCostCentres]
                        cc[i].selected = !cc[i].selected
                        setSelectedCostCentres(cc)
                      }}
                    />
                    {cc.costCentre} ({cc.name})
                  </Grid>)}
                </>}
              </Grid>
            </ConfirmationDialog>
          </>}
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            color="secondary"
            startIcon={<DownloadIcon />}
            onClick={() => {
              window.open(getFullBackendUrl(`/backend/pdf/downloadOffer/${props.version.id}`), '_blank');
            }}
          >
            {i18next.t('invoices-download-proforma-invoice')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <UnsavedChangesPrompt isDirty={isDirty} />
          <FormProvider {...form}>
            <CustomTabsControlled
              selected={selectedTab}
              handleChange={selected => setSelectedTab(selected)}
              headers={[
                i18next.t('invoice-lineitems-tab'),
                i18next.t('invoice-info-tab'),
                i18next.t('invoice-generated-tab')
              ]}
              hidden={[false, false, false, false, generatedInvoices.length === 0]}
              icons={[
                canEdit && ((canApprove && !hasAllDaysAccepted) || Object.keys(validationErrors).findIndex(v => v.startsWith('days')) >= 0 || Object.keys(validationErrors).findIndex(v => v.startsWith('extraLineItems')) >= 0 || Object.keys(validationErrors).findIndex(v => v.startsWith('cancellations')) >= 0) ? <ErrorIcon /> : undefined,
                canEdit && ((canApprove && !hasSenderAccepted) || Object.keys(validationErrors).findIndex(v => v.startsWith('sender')) >= 0) ? <ErrorIcon /> : undefined,
                undefined
              ]}
              tabs={[
                <Grid container spacing={2}>
                  {daysFields.map((day, index) => (
                    <Grid item xs={12} key={index}>
                      <InvoiceFormDay
                        dayIndex={index}
                        disabled={!canEdit || isSubmitting}
                        canEditItems={canEditItems && !isSubmitting}
                        canAccept={canApprove}
                        isPricesNet={priceList.isPricesNet}
                        setHasAllAccepted={setHasAllDaysAccepted}
                        lineItemAttendanceChanged={lineItemAttendanceChanged}
                        lineItemCountChanged={lineItemCountChanged}
                        {...props}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                          {canEdit && Object.keys(validationErrors).findIndex(v => v.startsWith('extraLineItems')) >= 0 ? <ErrorIcon /> : undefined}
                          {i18next.t('invoice-extras-tab')} ({extraLineItemsFields.length})
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              {extraLineItemsFields.map((li, index) => (
                                <InvoiceFormExtraLineItem key={index}
                                  extraLineItemIndex={index}
                                  disabled={!canEdit || isSubmitting}
                                  isPricesNet={priceList.isPricesNet}
                                  extraLineItemRemove={(index) => {
                                    setValue(`extraLineItems.${index}.isDeleted`, true)
                                    onTotalsChanged();
                                  }}
                                  {...props}
                                />
                              ))}
                            </Grid>
                          </Grid>
                          {canEdit && extraLineItemsFields.map((li, index) => <InvoiceFormExtraLineItemListener key={`L_${index}`} extraLineItemIndex={index} extraLineItemChanged={extraLineItemChanged} />)}
                          {canEdit && !isSubmitting && <Grid item xs={12}>
                            <Select
                              value=""
                              displayEmpty
                              onChange={event => {
                                if (!event.target.value) return;
                                extraLineItemAdd(`${event.target.value}`);
                              }}
                            >
                              <MenuItem value={''}>
                                <em>{i18next.t('invoice-extras-selectsku')}</em>
                              </MenuItem>
                              {props.quickPriceCalculator.allProductsDb && props.quickPriceCalculator.allProductsDb.map((p, index) => (
                                <MenuItem key={`P_${index}`} value={p.sku}>
                                  {p.sku} {p.name}
                                </MenuItem>
                              ))}
                              <ListSubheader><Divider /></ListSubheader>
                              {props.quickPriceCalculator.allBundlesDb && props.quickPriceCalculator.allBundlesDb.map((b, index) => (
                                <MenuItem key={`B_${index}`} value={b.sku}>
                                  {b.sku} {b.name}
                                </MenuItem>
                              ))}
                              <ListSubheader><Divider /></ListSubheader>
                              {props.quickPriceCalculator.allFacilitiesDb && props.quickPriceCalculator.allFacilitiesDb.map((f, index) => (
                                <MenuItem key={`F_${index}`} value={f.sku}>
                                  {f.sku} {f.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  {cancellationsFields.map((day, index) => (
                    <Grid item xs={12} key={index}>
                      <InvoiceFormCancellation key={index}
                        ruleIndex={index}
                        disabled={!canEdit || isSubmitting}
                        {...props}
                      />
                    </Grid>
                  ))}
                  {cancellationsFields.length > 0 && <>
                    <Grid item xs={12} sm={2}>
                      <FormInputCurrency name={'agreedCancellationWaiveNet'} label={i18next.t('invoice-cancellation-agreedwaive')} currency={version.priceList.currency} control={control} disabled />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormInputCurrency name={'manualCancellationWaiveNet'} label={i18next.t('invoice-cancellation-manualwaive')} currency={version.priceList.currency} control={control} disabled={!canEdit || isSubmitting} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {canEdit &&<Button color="secondary" variant="contained" onClick={() => {
                        const { cancellationFeeNet } = calculateTotals({
                          agreedCancellationWaiveNet: getValues('agreedCancellationWaiveNet'),
                          manualCancellationWaiveNet: getValues('manualCancellationWaiveNet'),
                          contentBlocks: [
                            ...getValues('days').map(d => ({ type: EOfferVersionContentBlockType.LINE_ITEMS, lineItems: d.lineItems })),
                            {
                              type: EOfferVersionContentBlockType.LINE_ITEMS,
                              lineItems: getValues('extraLineItems').filter(li => !li.isDeleted)
                            },
                            ...getValues('cancellations').map(d => ({ type: EOfferVersionContentBlockType.CANCELLATION_ITEMS, lineItems: d.lineItems })),
                          ]
                        })
                        setValue('manualCancellationWaiveNet', cancellationFeeNet)
                      }}>Kompletter Stornoverzicht</Button>}
                    </Grid>
                  </>}
                </Grid>,
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormInputText name="sender.businessName" control={control} label={i18next.t('invoice-sender-name')} disabled={!canEdit || isSubmitting} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText name="sender.businessAddress1" control={control} label={i18next.t('invoice-sender-address1')} disabled={!canEdit || isSubmitting} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText name="sender.businessAddress2" control={control} label={i18next.t('invoice-sender-address2')} disabled={!canEdit || isSubmitting} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText name="sender.businessAddress3" control={control} label={i18next.t('invoice-sender-address3')} disabled={!canEdit || isSubmitting} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText name="sender.businessAddress4" control={control} label={i18next.t('invoice-sender-address4')} disabled={!canEdit || isSubmitting} />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormInputText name="sender.businessZip" control={control} label={i18next.t('invoice-sender-zip')} disabled={!canEdit} required />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText name="sender.businessCity" control={control} label={i18next.t('invoice-sender-city')} disabled={!canEdit} required />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputCountryDropdown
                          name="sender.businessCountry"
                          control={control}
                          label={i18next.t('invoice-sender-country')}
                          disabled={!canEdit || isSubmitting}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormInputText name="sender.businessEmail" control={control} label={i18next.t('invoice-sender-email')} disabled={!canEdit || isSubmitting} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText name="sender.businessPhone" control={control} label={i18next.t('invoice-sender-phone')} disabled={!canEdit || isSubmitting} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText name="sender.contactName" control={control} label={i18next.t('invoice-sender-contact')} disabled={!canEdit || isSubmitting} />
                      </Grid>
                      <Grid item xs={12}>
                        <FormInputText name="sender.comment" control={control} label={i18next.t('invoice-sender-comment')} disabled={!canEdit || isSubmitting} />
                      </Grid>
                    </Grid>
                  </Grid>
                  {canEdit && canApprove &&
                    <Grid item xs={12} justifyItems={'end'}>
                      <FormInputCheckbox name="sender.accepted" label={i18next.t('invoice-sender-accepted')} control={control} />
                    </Grid>
                  }
                </Grid>,
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Grid container spacing={1}>
                      {generatedInvoices && generatedInvoices.filter(gci => gci.type === EOfferVersionType.CLIENTINVOICE).map((gci, i) => <Grid item xs={12} key={i}>
                        <Button color="secondary" variant="contained" disabled={showGeneratedInvoiceId === gci.id} onClick={() => setShowGeneratedInvoiceId(gci.id)}>
                          {gci.title || `Beleg ${i + 1}`}
                        </Button>
                      </Grid>)}
                      {generatedInvoices && generatedInvoices.filter(gci => gci.type === EOfferVersionType.COSTCENTRE).map((gci, i) => <Grid item xs={12} key={i}>
                        <Button color="secondary" variant="contained" disabled={showGeneratedInvoiceId === gci.id} onClick={() => setShowGeneratedInvoiceId(gci.id)}>
                          {gci.title || `Kostenstellen-Beleg ${i + 1}`}
                        </Button>
                      </Grid>)}
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    {showGeneratedInvoiceId && <iframe src={getFullBackendUrl(`/backend/html/showOffer/${showGeneratedInvoiceId}`)} width="100%" height="900"></iframe>}
                  </Grid>
                </Grid>
              ]}
            />
          </FormProvider>
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            {i18next.t('invoice-totals')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {i18next.t('invoice-totals-net')}: {formatPrice(watch('totalPriceNet'), version.priceList.currency)}
        </Grid>
        <Grid item xs={12}>
          {i18next.t('invoice-totals-taxes')}: {formatPrice(watch('totalPriceGross') - watch('totalPriceNet'), version.priceList.currency)}
        </Grid>
        <Grid item xs={12}>
          {i18next.t('invoice-totals-gross')}: {formatPrice(watch('totalPriceGross'), version.priceList.currency)}
        </Grid>
      </Grid>
    </>
  );
}

export default function Invoice(props: InvoiceProps) {
  const offerQuery = useQuery(INVOICE_VIEW_QUERY, {
    variables: { id: props.id }
  });

  const loading = offerQuery.loading;
  const error = offerQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else if (!offerQuery.data?.viewWlOffer || !offerQuery.data?.viewWlOfferInvoiceVersion) return <Navigate to={`/offers`} />

  const calc = new QuickPriceCalculatorLister();
  calc.hydrate(offerQuery.data!.invoiceQuickPriceDehydrate!);

  const pt = new ProductTextSelector();
  pt.hydrate(offerQuery.data!.invoiceProductTextDehydrate!);

  return (
    <InvoiceForm key={`invoice_${props.id}_${offerQuery.data!.viewWlOfferInvoiceVersion!.updatedAt}`} offer={offerQuery.data!.viewWlOffer!} version={offerQuery.data!.viewWlOfferInvoiceVersion!} invoices={offerQuery.data!.viewWlOfferGeneratedInvoices} event={offerQuery.data!.viewWlOfferInvoiceEvent} quickPriceCalculator={calc} productTexts={pt} />
  );
}
