import _ from 'lodash';
import moment from 'moment';
import { QuickPriceCalculatorTypeLister } from '../pricelist/quickprice_lister';
import { QuickPriceCalculatorTypeWidget } from '../pricelist/quickprice_widget';
import { ICalcLineItem, ICalcOptions, EContent_HotelServiceTypeAssignment } from '../pricelist/quickprice';

export type EAM_EventProductLookupCodes = "TAGESPAUSCHALE" | "HALBTAGESPAUSCHALE" | "ADDON_COFFEE" | "ADDON_COFFEEBREAK" | "ADDON_LUNCH" | "ADDON_DINNER" | "ROOM_EZ" | "ROOM_DZ";
export type EAM_EventRoomType = "ROOM_DOUBLE" | "ROOM_SINGLE" | "ROOM_NONE";

const _allLookupCodes: EAM_EventProductLookupCodes[] = ["TAGESPAUSCHALE", "HALBTAGESPAUSCHALE", "ADDON_COFFEE", "ADDON_COFFEEBREAK", "ADDON_LUNCH", "ADDON_DINNER", "ROOM_EZ", "ROOM_DZ"]

interface IProfileAttendance extends IDirectPay {
  days: IProfileAttendanceDay[]
}

export interface IProfileAttendanceDay extends IAttendance, IShow, IDirectPay {
  day: number,
}

export interface IAttendance {
  attending?: boolean | null;
  morning?: boolean | null;
  forenoon?: boolean | null;
  lunch?: boolean | null;
  afternoon?: boolean | null;
  dinner?: boolean | null;
  room?: EAM_EventRoomType | null;
}
interface IShow {
  morningShow?: boolean | null;
  forenoonShow?: boolean | null;
  lunchShow?: boolean | null;
  afternoonShow?: boolean | null;
  dinnerShow?: boolean | null;
  roomShow?: boolean | null;
}

interface IDirectPay {
  serviceDirectPay?: boolean | null;
  roomDirectPay?: boolean | null;
  extraDirectPay?: boolean | null;
  parkingDirectPay?: boolean | null;
}

export const isValidAttendanceLookupCode = (code: string): code is EAM_EventProductLookupCodes => {
  return _allLookupCodes.findIndex(a => a === code) >= 0
}

export const mapAttendanceRegistered = (lookupCode: EAM_EventProductLookupCodes, aday: IAttendance) => {
  if (!!aday.attending) {
    if (lookupCode === 'TAGESPAUSCHALE') {
      return aday.forenoon && aday.afternoon
    }
    if (lookupCode === 'HALBTAGESPAUSCHALE') {
      return (aday.forenoon || aday.afternoon) && !(aday.forenoon && aday.afternoon)
    }
    if (lookupCode === 'ADDON_COFFEEBREAK') {
      return aday.forenoon || aday.afternoon
    }
    if (lookupCode === 'ADDON_COFFEE') {
      return !!aday.morning
    }
    if (lookupCode === 'ADDON_LUNCH') {
      return !!aday.lunch
    }
    if (lookupCode === 'ADDON_DINNER') {
      return !!aday.dinner
    }
  }
  if (lookupCode === 'ROOM_DZ') {
    return aday.room === 'ROOM_DOUBLE'
  }
  if (lookupCode === 'ROOM_EZ') {
    return aday.room === 'ROOM_SINGLE'
  }
  return false
}

const _mapAttendance2SKUs = (aday: IAttendance, qp: QuickPriceCalculatorTypeLister | QuickPriceCalculatorTypeWidget) => {
  const result: { sku: string, code: EAM_EventProductLookupCodes, count: number }[] = []

  const _getSKU = (code: EAM_EventProductLookupCodes, assignment?: string) => {
    let st = (assignment && qp.allServiceTypesDb?.find(st => st.listerServiceTypeAssignment === assignment))
      || qp.allServiceTypesDb?.find(st => st.listerServiceTypeAssignment === undefined)
      || qp.allServiceTypesDb?.[0]

    if (!st) return null

    const stp = st.products.find(p => p.product ? qp.allProductsDb?.find(ap => ap.lookupCode === code && ap.id === p.product!.id)?.sku : p.bundle ? qp.allBundlesDb?.find(ab => ab.lookupCode === code && ab.id === p.bundle!.id)?.sku : null)
    if (stp && stp.product) return stp.product.sku
    if (stp && stp.bundle) return stp.bundle.sku

    const sta = st.addons.find(a => a.product ? qp.allProductsDb?.find(aa => aa.lookupCode === code && aa.id === a.product!.id)?.sku : null)
    if (sta && sta.product) return sta.product.sku

    return null
  }
  const _addResult = (count: number, code: EAM_EventProductLookupCodes, assignment?: string) => {
    const sku = _getSKU(code, assignment)
    if (sku) {
      const existing = result.find(r => r.sku === sku)
      if (existing) {
        existing.count += count
      } else {
        result.push({ sku, code, count })
      }
    }
  }

  _allLookupCodes.forEach(code => {
    _addResult(0, code, EContent_HotelServiceTypeAssignment.REGULAR)
    _addResult(0, code, EContent_HotelServiceTypeAssignment.MEDIUM)
    _addResult(0, code, EContent_HotelServiceTypeAssignment.SMALL)
  })

  if (aday.attending) {
    if (aday.forenoon && aday.lunch && aday.afternoon) {
      _addResult(1, 'TAGESPAUSCHALE', EContent_HotelServiceTypeAssignment.REGULAR)
      if (aday.morning) _addResult(1, 'ADDON_COFFEE', EContent_HotelServiceTypeAssignment.REGULAR)
      if (aday.dinner) _addResult(1, 'ADDON_DINNER', EContent_HotelServiceTypeAssignment.REGULAR)
      if (aday.room === 'ROOM_SINGLE') _addResult(1, 'ROOM_EZ', EContent_HotelServiceTypeAssignment.REGULAR)
      if (aday.room === 'ROOM_DOUBLE') _addResult(1, 'ROOM_DZ', EContent_HotelServiceTypeAssignment.REGULAR)
    } else if (aday.forenoon && aday.afternoon) {
      _addResult(1, 'TAGESPAUSCHALE', EContent_HotelServiceTypeAssignment.MEDIUM)
      if (aday.morning) _addResult(1, 'ADDON_COFFEE', EContent_HotelServiceTypeAssignment.MEDIUM)
      if (aday.dinner) _addResult(1, 'ADDON_DINNER', EContent_HotelServiceTypeAssignment.MEDIUM)
      if (aday.room === 'ROOM_SINGLE') _addResult(1, 'ROOM_EZ', EContent_HotelServiceTypeAssignment.MEDIUM)
      if (aday.room === 'ROOM_DOUBLE') _addResult(1, 'ROOM_DZ', EContent_HotelServiceTypeAssignment.MEDIUM)
    } else if (aday.forenoon || aday.afternoon) {
      _addResult(1, 'TAGESPAUSCHALE', EContent_HotelServiceTypeAssignment.SMALL)
      if (aday.lunch) _addResult(1, 'ADDON_LUNCH', EContent_HotelServiceTypeAssignment.SMALL)
      if (aday.morning) _addResult(1, 'ADDON_COFFEE', EContent_HotelServiceTypeAssignment.SMALL)
      if (aday.dinner) _addResult(1, 'ADDON_DINNER', EContent_HotelServiceTypeAssignment.SMALL)
      if (aday.room === 'ROOM_SINGLE') _addResult(1, 'ROOM_EZ', EContent_HotelServiceTypeAssignment.SMALL)
      if (aday.room === 'ROOM_DOUBLE') _addResult(1, 'ROOM_DZ', EContent_HotelServiceTypeAssignment.SMALL)
    }
  } else {
    if (aday.room === 'ROOM_SINGLE') _addResult(1, 'ROOM_EZ')
    if (aday.room === 'ROOM_DOUBLE') _addResult(1, 'ROOM_DZ')
  }

  return result
}

export const mapAttendanceShow = (lookupCode: EAM_EventProductLookupCodes, aday: IShow) => {
  const _cf = (flag: string) => _.isBoolean((aday as any)[flag]) ? !!(aday as any)[flag] : true

  if (lookupCode === 'TAGESPAUSCHALE') {
    return _cf('forenoonShow') || _cf('afternoonShow') || _cf('lunchShow')
  }
  if (lookupCode === 'ADDON_COFFEE') {
    return _cf('morningShow')
  }
  if (lookupCode === 'ADDON_COFFEEBREAK') {
    return _cf('forenoonShow') || _cf('afternoonShow')
  }
  if (lookupCode === 'ADDON_LUNCH') {
    return _cf('lunchShow')
  }
  if (lookupCode === 'ADDON_DINNER') {
    return _cf('dinnerShow')
  }
  if (lookupCode === 'ROOM_DZ' || lookupCode === 'ROOM_EZ') {
    return _cf('roomShow')
  }
  return true
}

export const mapAttendanceDirectPay = (lookupCode: EAM_EventProductLookupCodes, g: IDirectPay, a: IDirectPay, aday: IDirectPay) => {
  const _cf = (flag: string) => _.isBoolean((aday as any)[flag]) ? !!(aday as any)[flag] : _.isBoolean((a as any)[flag]) ? !!(a as any)[flag] : _.isBoolean((g as any)[flag]) ? !!(g as any)[flag] : false

  if (lookupCode === 'TAGESPAUSCHALE' || lookupCode === 'HALBTAGESPAUSCHALE') {
    return _cf('serviceDirectPay')
  }
  if (lookupCode === 'ADDON_COFFEE' || lookupCode === 'ADDON_COFFEEBREAK' || lookupCode === 'ADDON_LUNCH' || lookupCode === 'ADDON_DINNER') {
    return _cf('serviceDirectPay')
  }
  if (lookupCode === 'ROOM_DZ' || lookupCode === 'ROOM_EZ') {
    return _cf('roomDirectPay')
  }
  return false
}

export const mapShowAttendance = (lookupCode: EAM_EventProductLookupCodes, shows: boolean[], attendances: IShow[]) => {
  return shows.map((show, index) => {
    const attendance = attendances[index]
    if (lookupCode === 'TAGESPAUSCHALE') {
      attendance.forenoonShow = show
      attendance.afternoonShow = show
    } else if (lookupCode === 'HALBTAGESPAUSCHALE') {
      attendance.forenoonShow = show
    } else if (lookupCode === 'ADDON_COFFEE') {
      attendance.morningShow = show
    } else if (lookupCode === 'ADDON_COFFEEBREAK') {
      attendance.forenoonShow = show
    } else if (lookupCode === 'ADDON_LUNCH') {
      attendance.lunchShow = show
    } else if (lookupCode === 'ADDON_DINNER') {
      attendance.dinnerShow = show
    } else if (lookupCode === 'ROOM_DZ' || lookupCode === 'ROOM_EZ') {
      attendance.roomShow = show
    }
    return attendance
  })
}

export const mapProfileAttendanceDay2LineItems = (g: IDirectPay, a: IProfileAttendance, day: IProfileAttendanceDay, qp: QuickPriceCalculatorTypeLister | QuickPriceCalculatorTypeWidget, ignoreDirectPay: boolean) =>  {
  const lineItems: { sku: string, code: EAM_EventProductLookupCodes, count: number }[] = []

  const skus = _mapAttendance2SKUs(day, qp)
  for (const sku of skus) {
    if (!ignoreDirectPay) {
      const show = mapAttendanceShow(sku.code, day)
      const directPay = mapAttendanceDirectPay(sku.code, g, a, day)

      if (directPay && show) continue
    }

    const li = lineItems.find(l => l.sku === sku.sku)
    if (li) {
      li.count += sku.count
    } else {
      lineItems.push({
        sku: sku.sku,
        code: sku.code,
        count: sku.count
      })
    }
  }
  return lineItems
}

export const mapLineItems2GuestCount = (lineItems: { sku: string, count: number }[], qp: QuickPriceCalculatorTypeLister | QuickPriceCalculatorTypeWidget) =>  {
  const skusOvernight = _.uniq([
    qp.allBundlesDb?.find(b => b.lookupCode === 'ROOM_EZ')?.sku,
    qp.allBundlesDb?.find(b => b.lookupCode === 'ROOM_DZ')?.sku,
    qp.allProductsDb?.find(p => p.lookupCode === 'ROOM_EZ')?.sku,
    qp.allProductsDb?.find(p => p.lookupCode === 'ROOM_DZ')?.sku
  ].filter(s => s).map(s => s!))

  const skusGuests = _.uniq([
    qp.allBundlesDb?.find(b => b.lookupCode === 'TAGESPAUSCHALE')?.sku,
    qp.allBundlesDb?.find(b => b.lookupCode === 'HALBTAGESPAUSCHALE')?.sku,
    qp.allProductsDb?.find(p => p.lookupCode === 'TAGESPAUSCHALE')?.sku,
    qp.allProductsDb?.find(p => p.lookupCode === 'HALBTAGESPAUSCHALE')?.sku
  ].filter(s => s).map(s => s!))

  return {
    overnightGuests: lineItems.filter(li => skusOvernight.includes(li.sku)).reduce((agg, c) => agg + c.count, 0),
    totalGuests: lineItems.filter(li => skusGuests.includes(li.sku)).reduce((agg, c) => agg + c.count, 0),
  }
}

export const mapProfileAttendances2LineItems = (gevent: IDirectPay, attendances: IProfileAttendance[], startDate: Date, qp: QuickPriceCalculatorTypeLister | QuickPriceCalculatorTypeWidget, params?: {
  includeEmpty?: boolean,
  ignoreDirectPay?: boolean,
  findPrice?: (day: number, sku: string) => ReturnType<NonNullable<ICalcOptions['priceSelector']>> | undefined
}) =>  {
  let lineItems: ICalcLineItem[] = []

  const days = _.uniq(attendances.reduce((agg, a) => [...agg, ...a.days.map(d => d.day)], [] as number[])).sort()
  for (const day of days) {
    const date = moment(startDate).add(day, 'days').toDate();

    for (const attendance of attendances) {
      const attendanceDay = attendance.days.find(d => d.day == day)
      if (!attendanceDay) continue;

      const daySkuCounts = mapProfileAttendanceDay2LineItems(gevent, attendance, attendanceDay, qp, !!params?.ignoreDirectPay)

      for (const daySkuCount of daySkuCounts) {
        if (day < 0 && daySkuCount.code !== 'ROOM_EZ' && daySkuCount.code !== 'ROOM_DZ') continue

        const li = lineItems.find(l => l.sku === daySkuCount.sku && l.day === day)
        const itemCount = (li ? li.count : 0) + daySkuCount.count
        const newLineItem = qp.getSKULineItem(daySkuCount.sku, { date, itemCount, guestCount: attendances.length }, null, {
          allowNoPrices: true,
          priceSelector: params?.findPrice ? (sel) => params.findPrice!(day, daySkuCount.sku) : undefined
        }) //tageweise guest count ?
        if (!newLineItem) continue

        if (li) {
          lineItems = lineItems.filter(r => r.day === li.day ? r.sku !== daySkuCount.sku : true)
        }
        newLineItem.day = day
        lineItems.push(newLineItem)
      }
    }
    if (!!params?.includeEmpty) {
      for (const code of _allLookupCodes) {
        if (day < 0 && code !== 'ROOM_EZ' && code !== 'ROOM_DZ') continue

        const sku = qp.getSKUForLookupCode(code)
        if (!sku) continue

        const li = lineItems.find(l => l.sku === sku && l.day === day)
        if (!li) {
          const newLineItem = qp.getSKULineItem(sku, { date, itemCount: 0, guestCount: attendances.length }, null, {
            allowNoPrices: true,
            priceSelector: params?.findPrice ? (sel) => params.findPrice!(day, sku) : undefined
          })
          if (!newLineItem) continue
          newLineItem.day = day
          lineItems.push(newLineItem)
        }
      }
    }
  }
  return {
    lineItems,
    input: {
      prevdayGuests: mapLineItems2GuestCount(lineItems.filter(li => li.day === -1), qp).overnightGuests,
      days: days.map(day => ({
        day: day,
        overnightGuests: mapLineItems2GuestCount(lineItems.filter(li => li.day === day), qp).overnightGuests,
        totalGuests: mapLineItems2GuestCount(lineItems.filter(li => li.day === day), qp).totalGuests,
      }))
    }
  }
}


