import { createContext } from 'react';
import yup from 'validation';
import i18next from 'i18next';
import { ECountryCode, ELanguageCode, EOfferVersionContentBlockFormFieldType, EOfferVersionContentBlockType } from '__generated__/graphql';
import { QuickPriceCalculatorTypeLister } from 'semshared/pricelist/quickprice_lister';
import { QuickPriceCalculatorTypeWidget } from 'semshared/pricelist/quickprice_widget';

export const taxesSchema = yup.object().shape({
  taxTypeId: yup.number().required(),
  taxRateId: yup.number().required(),
  name: yup.string().required(),
  rate: yup.number().required(),
  price: yup.number().required(),
});

export const componentsSchema = yup.object().shape({
  taxTypeId: yup.number().required(),
  name: yup.string().required(),
  price: yup.number().required().min(0).label(i18next.t('offerblock-lineitem-priceitem')),
});

export const lineItemSchema = yup.object().shape({
  day: yup.number().required(),
  count: yup.number().required().label(i18next.t('offerblock-lineitem-count')),
  sku: yup.string().nullable(),
  spaceId: yup.number().nullable(),
  header: yup.string().required().label(i18next.t('offerblock-lineitem-header')),
  details: yup.string(),
  priceItem: yup.number().required().min(0).label(i18next.t('offerblock-lineitem-priceitem')),
  priceGross: yup.number().required().min(0).label(i18next.t('offerblock-lineitem-pricegross')),
  priceNet: yup.number().required().min(0).label(i18next.t('offerblock-lineitem-pricenet')),
  sequence: yup.number().required(),
  components: yup.array().required().of(componentsSchema),
  taxes: yup.array().required().of(taxesSchema)
});

export const formFieldsSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.mixed<EOfferVersionContentBlockFormFieldType>().required(),
  name: yup.string().required(),
  label: yup.string().required(),
  placeholder: yup.string().nullable(),
  sequence: yup.number().required(),
  required: yup.boolean().nullable(),
  value: yup.string().nullable(),
})

export const contentBlockSchema = yup.object().shape({
  sequence: yup.number().required(),
  template: yup
    .string()
    .required()
    .when('type', {
      is: 'PLAIN_HTML',
      then: schema => schema.label(i18next.t('offerblock-plainhtml-html')),
    })
    .when('type', {
      is: 'INPUT_DAYS',
      then: schema => schema.label(i18next.t('offerblock-inputdays-html')),
    })
    .when('type', {
      is: 'LINE_ITEMS',
      then: schema => schema.label(i18next.t('offerblock-lineitems-html')),
    })
    .when('type', {
      is: 'CANCELLATION_ITEMS',
      then: schema => schema.label(i18next.t('offerblock-lineitems-html')),
    })
    .when('type', {
      is: 'CUSTOM_FORM',
      then: schema => schema.label(i18next.t('offerblock-customform-html')),
    }),
  type: yup.mixed<EOfferVersionContentBlockType>().required(),
  day: yup.number().nullable(),
  blockDescription: yup.string().nullable(),
  lineItems: yup.array().of(lineItemSchema),
  formFields: yup.array().of(formFieldsSchema),
  cancellationRule: yup.object().shape({
    daysToEvent: yup.number().required(),
    rate: yup.number().required(),
  }).nullable(),
});

export const clientSchema = yup.object().shape({
  email: yup.string().required().label(i18next.t('offertemplate-client-email')),
  phone: yup.string().nullable().label(i18next.t('offertemplate-client-phone')),
  firstname: yup.string().required().label(i18next.t('offertemplate-client-company-firstname')),
  lastname: yup.string().required().label(i18next.t('offertemplate-client-company-lastname')),
  company: yup.string().required().label(i18next.t('offertemplate-client-company-company')),
  country: yup.mixed<ECountryCode>().oneOf(Object.values(ECountryCode)).required().label(i18next.t('offertemplate-client-company-country')),
  address: yup.string().required().label(i18next.t('offertemplate-client-company-address')),
  city: yup.string().required().label(i18next.t('offertemplate-client-company-city')),
  zip: yup.string().required().label(i18next.t('offertemplate-client-company-zip')),
  billingCompany: yup.string().required().label(i18next.t('offertemplate-client-billing-company')),
  billingFirstname: yup.string().required().label(i18next.t('offertemplate-client-billing-firstname')),
  billingLastname: yup.string().required().label(i18next.t('offertemplate-client-billing-lastname')),
  billingAddress: yup.string().required().label(i18next.t('offertemplate-client-billing-address')),
  billingCity: yup.string().required().label(i18next.t('offertemplate-client-billing-city')),
  billingZip: yup.string().required().label(i18next.t('offertemplate-client-billing-zip')),
  billingCountry: yup.mixed<ECountryCode>().oneOf(Object.values(ECountryCode)).required().label(i18next.t('offertemplate-client-billing-country'))
})

export const validationSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().nullable(),
  filename: yup.string().nullable(),
  version: yup.string().required().label(i18next.t('offertemplate-version')),
  offerDate: yup.date().required().label(i18next.t('offertemplate-offerdate')),
  startDate: yup.date().required().label(i18next.t('offertemplate-startdate')),
  pageOptions: yup.object().shape({
    marginTop: yup.number().required().label(i18next.t('offertemplate-marginTop')),
    marginBottom: yup.number().required().label(i18next.t('offertemplate-marginBottom')),
    marginLeft: yup.number().required().label(i18next.t('offertemplate-marginLeft')),
    marginRight: yup.number().required().label(i18next.t('offertemplate-marginRight')),
    cssStyles: yup.string().nullable(),
    headerTemplate: yup.string().nullable(),
    footerTemplate: yup.string().nullable(),
  }),
  includeEmptyLineItems: yup.boolean().required(),
  contentBlocks: yup.array().required().min(1).of(contentBlockSchema).label(i18next.t('offertemplate-layout')),
  totalPriceNet: yup.number().required(),
  totalPriceGross: yup.number().required(),
  totalTax: yup.number().required(),
  totalTaxes: yup.array().required().of(taxesSchema),
  additionalInfo1: yup.string().nullable(),
  additionalInfo2: yup.string().nullable(),
  additionalInfo3: yup.string().nullable(),
  agreedCancellationWaiveNet: yup.number().nullable(),
  manualCancellationWaiveNet: yup.number().nullable(),
  client: clientSchema.nullable(),
  input: yup.object().nullable().shape({
    language: yup.mixed<ELanguageCode>().required().oneOf(Object.values(ELanguageCode)),
    startDate: yup.date().required(),
    endDate: yup.date().required(),
    prevdayGuests: yup.number().required(),
    comment: yup.string().nullable(),
    serviceType: yup.object().nullable().shape({
      sku: yup.string().required(),
      name: yup.string().required(),
      header: yup.string().required(),
      details: yup.string()
    }),
    days: yup.array().required().of(
      yup.object().shape({
        day: yup.number().required(),
        addons: yup.array().required().of(
          yup.object().shape({
            count: yup.number().nullable(),
            sku: yup.string().required(),
            header: yup.string().required()
          }),
        ),
        occupancy: yup.array().required().of(
          yup.object().shape({
            room: yup.number().required(),
            occupancy: yup.string().required(),
            seating: yup.string().nullable(),
          }),
        ),
        overnightGuests: yup.number().required(),
        totalGuests: yup.number().required(),
      })
    )
  })
});

export type OfferVersionFormType = yup.InferType<typeof validationSchema>;
export type ContentBlockFormType = yup.InferType<typeof contentBlockSchema>;
export type LineItemFormType = yup.InferType<typeof lineItemSchema>;
export type FormFieldsFormType = yup.InferType<typeof formFieldsSchema>;
export type TaxesFormType = yup.InferType<typeof taxesSchema>;

export const QuickPriceCalculatorContext = createContext<QuickPriceCalculatorTypeLister | QuickPriceCalculatorTypeWidget | null>(null);
