import _, { add } from 'lodash';
import { TaxRateSelector, ITaxRate, ITaxType } from './taxselector';
import { PriceListSelector, PriceListInput, IPriceList } from './pricelistselector';
import { PriceSelector, PriceSelectorInput, IPriceSelector, IPriceRule, PriceSelectorOutput } from './priceselector';
import { roundPrice } from '../utils/round';

export const NO_PRICE = -100000

export const EContent_HotelServiceTypeAssignment = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  REGULAR: 'REGULAR'
};
export const EPricing_ProductLookupCode = {
  ROOM_DZ: 'ROOM_DZ',
  ROOM_EZ: 'ROOM_EZ',
  ADDON_COFFEE: 'ADDON_COFFEE',
  ADDON_DINNER: 'ADDON_DINNER',
};
export const EPricing_ProductPayment = {
  BYGUESTANDDAY: 'BYGUESTANDDAY',
  BYCOUNT: 'BYCOUNT',
  BYGUEST: 'BYGUEST',
  BYROOMANDDAY: 'BYROOMANDDAY',
  BYROOM: 'BYROOM'
};
export const EPricing_FacilityPayment = {
  BYCOUNT: 'BYCOUNT',
  BYDAY: 'BYDAY',
  BYGUEST: 'BYGUEST'
};
export const EPricing_FacilityLookupCode = {
  SEMINARROOM_FULLDAY: 'SEMINARROOM_FULLDAY',
  SEMINARROOM_SEMIDAY: 'SEMINARROOM_SEMIDAY',
};
export const EWL_OfferRoomOccupancy = {
  FULLDAY: 'FULLDAY',
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON'
};

export interface IQuickPriceInput<TQuickPriceDay extends IQuickPriceDay> {
  comment?: string | null;
  title?: string | null;
  days: TQuickPriceDay[];
  endDate: Date;
  language: string;
  startDate: Date;
}
export interface IQuickPriceAddon {
  count?: number | null;
  lookupCode?: string | null;
  sku?: string | null;
}
export interface IQuickPriceDay {
  addonFacilities?: IQuickPriceAddon[] | null;
  addonProducts?: IQuickPriceAddon[] | null;
  day: number;
  seating: string[];
  totalGuests: number;
}

export interface ICalcPriceOutput {
  priceList: IPriceList;
  hotel: IHotel;
  totalPriceNet: number;
  totalPriceGross: number;
  totalTaxes: number;
  taxes: ICalcTax[];
  lineItems: ICalcLineItem[];
};

export interface ICalcLineItem {
  day: number;
  sku: string;
  name: string;
  product?: IProduct | null;
  bundle?: IProductBundle | null;
  facility?: IFacility | null;
  selector?: IPriceSelector | null;
  isIncluded: boolean;
  count: number;
  priceItem: number;
  priceGross: number;
  priceNet: number;
  components: ICalcPriceComponent[];
  taxes: ICalcTax[];
};

export interface ICalcPriceComponent {
  type: ITaxType;
  price: number;
};

export interface ICalcTax {
  type: ITaxType;
  rate: ITaxRate;
  price: number;
};

export interface IHotel {
  id: number;
  businessCountry: string;
}

export interface IPrice {
  price: number | null;
  bundlePriceFromProduct?: boolean | null;
  components?: IPriceComponent[];
  selector?: IPriceSelector | null;
}
export interface IPriceComponent {
  taxTypeId: number;
  price: number;
}

export interface IFacility {
  id: number;
  name: string;
  sku: string;
  lookupCode: string | null;
  recurring: string;
  components: IComponent[];
}
export interface IProduct {
  id: number;
  name: string;
  sku: string;
  lookupCode: string | null;
  isDeduction: boolean;
  recurring: string;
  components: IComponent[];
}
export interface IComponent {
  taxTypeId: number;
}
export interface IId {
  id: number;
  sku: string;
}
export interface IProductBundle {
  id: number;
  name: string;
  sku: string;
  lookupCode: string | null;
  recurring: string;
  components: IComponent[];
  bundleItems: IProductBundleItem[];
}
export interface IProductBundleItem {
  productId: number;
  includedCount: number;
}

export interface IServiceType {
  id: number;
  name: string;
  sku: string;
  listerServiceTypeAssignment?: string;
  products: IServiceTypeProduct[];
  facilities: IServiceTypeFacility[];
  addons: IServiceTypeAddon[];
}
export interface IServiceTypeProduct {
  isForDayVisitor: boolean;
  isForDeparture: boolean;
  isForOvernight: boolean;
  isForSemidayVisitor: boolean;
  isForSemidayOvernight: boolean;
  isForSemidayDeparture: boolean;
  isForNextday: boolean;
  isForRoom: boolean;
  isForPrevday: boolean;
  bundle: IId | null;
  product: IId | null;
}
export interface IServiceTypeFacility {
  facility: IId;
  isForDay: boolean;
  isForSemiday: boolean;
  includedCount: number;
}
export interface IServiceTypeAddon {
  approvalLimit?: number | null;
  product: IId | null;
  facility: IId | null;
}
export interface ICalcOptions {
  addonsOnly?: boolean;
  allowNoPrices?: boolean;
  priceSelector?: (sel: PriceSelectorInput) => Omit<PriceSelectorOutput<IPriceSelector>, 'selector'> | null | undefined
}

export class QuickPriceError extends Error {
  readonly extensions: any | undefined;
  constructor(message: string, extensions?: any | undefined) {
    super(message);
    this.extensions = extensions;
  }
}

export function mergeTaxes(destination: ICalcTax[], taxes: ICalcTax[]) {
  for (const tax of taxes) {
    const existingTax = destination.find(t => t.type.id === tax.type.id);
    if (existingTax) {
      if (existingTax.price === NO_PRICE || tax.price === NO_PRICE) existingTax.price = NO_PRICE
      else existingTax.price += tax.price;
    } else {
      destination.push({ ...tax });
    }
  }
  return _.orderBy(destination, d => d.type.sequence, 'asc');
}
export function  mergeComponents(destination: ICalcPriceComponent[], components: ICalcPriceComponent[]) {
  for (const component of components) {
    const existingComponent = destination.find(t => t.type.id === component.type.id);
    if (existingComponent) {
      if (existingComponent.price === NO_PRICE || existingComponent.price === NO_PRICE) existingComponent.price = NO_PRICE
      else existingComponent.price += component.price;
    } else {
      destination.push({ ...component });
    }
  }
  return _.orderBy(destination, d => d.type.sequence, 'asc');
}

export function mergeLineItem(result: ICalcPriceOutput, lineItem: ICalcLineItem, addCount: boolean = true) {
  const existingLineItem = result.lineItems.find(
    l =>
      ((l.day === null && lineItem.day === null) || l.day === lineItem.day) &&
      l.sku === lineItem.sku &&
      l.isIncluded === lineItem.isIncluded &&
      ((l.selector && lineItem.selector && l.selector.id === lineItem.selector.id) || (!l.selector && !lineItem.selector)),
  );
  if (existingLineItem) {
    if (addCount) {
      existingLineItem.count += lineItem.count;
      if (existingLineItem.priceGross === NO_PRICE || lineItem.priceGross === NO_PRICE) existingLineItem.priceGross = NO_PRICE
      else existingLineItem.priceGross += lineItem.priceGross;
      if (existingLineItem.priceNet === NO_PRICE || lineItem.priceNet === NO_PRICE) existingLineItem.priceNet = NO_PRICE
      else existingLineItem.priceNet += lineItem.priceNet;
      mergeTaxes(existingLineItem.taxes, lineItem.taxes);
    }
  } else {
    result.lineItems.push({ ...lineItem });
  }
}

export function  sumTotalPrices(result: ICalcPriceOutput) {
  result.totalPriceGross = result.lineItems.reduce((sum, li) => (sum === NO_PRICE || li.priceGross === NO_PRICE ? NO_PRICE : sum + li.priceGross), 0);
  result.totalPriceNet = result.lineItems.reduce((sum, li) => (sum === NO_PRICE || li.priceNet === NO_PRICE ? NO_PRICE : sum + li.priceNet), 0);
  result.taxes = [];
  for (const lineItem of result.lineItems) {
    mergeTaxes(result.taxes, lineItem.taxes);
  }
  result.totalTaxes = (result.totalPriceGross === NO_PRICE || result.totalPriceNet === NO_PRICE) ? NO_PRICE : result.totalPriceGross - result.totalPriceNet;
}

export abstract class QuickPriceCalculatorBase<
  TQuickPriceDay extends IQuickPriceDay,
  TQuickPriceInput extends IQuickPriceInput<TQuickPriceDay>,
  TCalcOptions extends ICalcOptions,
  TQuickPriceCalcOutput extends ICalcPriceOutput
> {
  public taxRateSelector: TaxRateSelector<ITaxRate, ITaxType> | null = null;

  public hotel: IHotel | null = null;

  public allProductsDb: IProduct[] | null = null;
  public allFacilitiesDb: IFacility[] | null = null;
  public allBundlesDb: IProductBundle[] | null = null;
  public allServiceTypesDb: IServiceType[] | null = null;

  public priceListSelector: PriceListSelector<IPriceList> | null = null;
  public priceSelector: PriceSelector<IPriceSelector, IPriceRule> | null = null;

  public hydrate(json: string) {
    const { allProductsDb, allFacilitiesDb, allBundlesDb, allServiceTypesDb, hotel, priceListSelector, priceSelector, taxRateSelector } =
      JSON.parse(json);

    this.taxRateSelector = new TaxRateSelector();
    this.taxRateSelector.hydrate(taxRateSelector);

    this.hotel = hotel;

    this.allProductsDb = allProductsDb;
    this.allFacilitiesDb = allFacilitiesDb;
    this.allBundlesDb = allBundlesDb;
    this.allServiceTypesDb = allServiceTypesDb;

    this.priceListSelector = new PriceListSelector();
    if (priceListSelector) {
      this.priceListSelector.hydrate(priceListSelector as string);
    }
    this.priceSelector = new PriceSelector();
    if (priceSelector) {
      this.priceSelector.hydrate(priceSelector as string);
    }
  }
  public dehydrate(): string {
    return JSON.stringify({
      taxRateSelector: this.taxRateSelector?.dehydrate(),
      hotel: this.hotel,
      allProductsDb: this.allProductsDb,
      allFacilitiesDb: this.allFacilitiesDb,
      allBundlesDb: this.allBundlesDb,
      allServiceTypesDb: this.allServiceTypesDb,
      priceListSelector: this.priceListSelector?.dehydrate(),
      priceSelector: this.priceSelector?.dehydrate(),
    });
  }

  public getPriceList(input: PriceListInput) {
    const priceList = this.priceListSelector?.findPriceList(input);
    if (priceList) {
      return priceList;
    } else {
      throw new QuickPriceError(`No pricelist found for ${input.date}`, {
        extensions: { code: 'NO_PRICELIST' },
      });
    }
  }
  public getServiceType = (serviceTypeSku: string) => {
    const serviceType = this.allServiceTypesDb?.find(s => s.sku === serviceTypeSku);
    if (!serviceType) {
      throw new QuickPriceError(`Service type ${serviceTypeSku} not found`, {
        extensions: { code: 'BAD_INPUT' },
      });
    }
    return serviceType;
  }

  public abstract calculatePrice(input: TQuickPriceInput, calcOptions?: TCalcOptions): TQuickPriceCalcOutput;

  public getFreeStyleLineItem(itemCount: number, price: number, isPricesNet: boolean, taxTypeId: number) {
    const taxRate = this.taxRateSelector?.getTaxRate({
      country: this.hotel!.businessCountry,
      date: new Date(),
      taxTypeId: taxTypeId,
    });
    if (!taxRate)
      throw new QuickPriceError(`No tax rate found for ${taxTypeId}`, {
        extensions: { code: 'BAD_PRICELIST', taxTypeId },
      });

    if (isPricesNet) {
      const priceNet = itemCount * price;
      const tax = {
        type: this.taxRateSelector?.getTaxType(taxTypeId)!,
        rate: taxRate,
        price: roundPrice(priceNet * taxRate.rate),
      };
      const priceGross = priceNet + tax.price;
      return {
        priceNet,
        priceGross,
        components: [
          {
            type: tax.type,
            price: price,
          },
        ],
        taxes: [tax],
      };
    } else {
      const priceGross = itemCount * price;
      const priceNet = roundPrice(priceGross / (1 + taxRate.rate));
      const tax = {
        type: this.taxRateSelector?.getTaxType(taxTypeId)!,
        rate: taxRate,
        price: priceGross - priceNet,
      };
      return {
        priceNet,
        priceGross,
        priceItem: price,
        components: [
          {
            type: tax.type,
            price: price,
          },
        ],
        taxes: [tax],
      };
    }
  }

  public getLookupCodeForSKU(sku: string) {
    return this.allBundlesDb?.find(b => b.sku === sku)?.lookupCode || this.allProductsDb?.find(p => p.sku === sku)?.lookupCode || this.allFacilitiesDb?.find(f => f.sku === sku)?.lookupCode
  }
  public getLookupCodesForSKUs(skus: string[]) {
    return skus.map(sku => this.getLookupCodeForSKU(sku))
  }

  public getSKUForLookupCode(code: string) {
    return this.allBundlesDb?.find(b => b.lookupCode === code)?.sku || this.allProductsDb?.find(p => p.lookupCode === code)?.sku || this.allFacilitiesDb?.find(f => f.lookupCode === code)?.sku
  }
  public getSKUsForLookupCode(code: string) {
    return [
      ...(this.allBundlesDb || []).filter(b => b.lookupCode === code),
      ...(this.allProductsDb || []).filter(p => p.lookupCode === code),
      ...(this.allFacilitiesDb || []).filter(f => f.lookupCode === code)
    ].map(s => s.sku)
  }
  public getSKUsForLookupCodes(codes: string[]) {
    return _.uniq(codes.map(code => this.getSKUsForLookupCode(code)).reduce((agg, s) => [...agg, ...s], []))
  }

  public getSKUIncludedInServiceType(sku: string, addons: boolean = false) {
    const serviceType = this.allServiceTypesDb?.find(s => s.sku === sku)
    if (!serviceType) return []

    const serviceTypeIncludedSkus = _.uniq([
      ...(addons ? serviceType.addons.map(a => a.facility ? a.facility.sku : a.product ? a.product.sku : null) : []),
      ...serviceType.facilities.map(f => f.facility.sku),
      ...serviceType.products.filter(p => p.product).map(p => p.product!.sku),
      ...serviceType.products.filter(p => p.bundle)
        .map(p => this.allBundlesDb?.find(b => b.id === p.bundle!.id))
        .filter(b => b)
        .reduce<string[]>((agg, b) => [...agg, ...b!.bundleItems.map(bi => this.allProductsDb?.find(p => p.id === bi.productId)?.sku)].filter(s => s).map(s => s!), [])
    ].filter(s => s).map(s => s!))
    return serviceTypeIncludedSkus
  }
  public getSKUBundleItems(sku: string) {
    const bundle = this.allBundlesDb?.find(b => b.sku === sku);
    if (!bundle) return  []

    return bundle.bundleItems.map(bi => ({ includedCount: bi.includedCount, product: this.allProductsDb?.find(p => p.id === bi.productId) }))
      .filter(s => s.product && s.product.sku).map(s => ({ includedCount: s.includedCount, sku: s.product!.sku!, lookupCode: s.product!.lookupCode }))
  }

  public getSKURequiredComponents(sku: string) {
    const bundle = this.allBundlesDb?.find(b => b.sku === sku);
    if (bundle) return bundle.components;

    const product = this.allProductsDb?.find(p => p.sku === sku);
    if (product) return product.components;

    const facility = this.allFacilitiesDb?.find(f => f.sku === sku);
    if (facility) return facility.components;

    return null;
  }

  public getTaxRateForType(taxTypeId: number) {
    const taxRate = this.taxRateSelector?.getTaxRate({
      country: this.hotel!.businessCountry,
      date: new Date(),
      taxTypeId: taxTypeId,
    });
    return taxRate || null;
  }
  public getSKULineItem(sku: string, params: { day?: number | null, date: Date, itemCount: number, guestCount?: number | null}, price?: IPrice | null, calcOptions?: ICalcOptions | null) {
    const bundle = this.allBundlesDb?.find(b => b.sku === sku);
    const product = this.allProductsDb?.find(p => p.sku === sku);
    const facility = this.allFacilitiesDb?.find(f => f.sku === sku);

    const priceList = this.getPriceList({ date: params.date });

    if (bundle) {
      return this.getBundleLineItem(
        params.day || 0,
        {
          priceListId: priceList.id,
          bundleId: bundle.id,
          date: params.date,
          guestCount: params.guestCount || 0,
          itemCount: params.itemCount,
        },
        price, calcOptions);
    } else if (product) {
      return this.getProductLineItem(
        params.day || 0,
        {
          priceListId: priceList.id,
          productId: product.id,
          date: params.date,
          guestCount: params.guestCount || 0,
          itemCount: params.itemCount,
        },
        price, calcOptions);
    } else if (facility) {
      return this.getFacilityLineItem(
        params.day || 0,
        {
          priceListId: priceList.id,
          facilityId: facility.id,
          date: params.date,
          guestCount: params.guestCount || 0,
          itemCount: params.itemCount,
        }, price, calcOptions);
    } else {
      throw new QuickPriceError(`No product found for SKU ${sku}`, {
        extensions: { code: 'BAD_PRODUCT', sku },
      });
    }
  }

  public getBundleLineItem(day: number, selInput: PriceSelectorInput, price?: IPrice | null, calcOptions?: ICalcOptions | null) {
    const bundle = this.allBundlesDb?.find(b => b.id === selInput.bundleId);
    if (!bundle) return null;

    if (!price && calcOptions && calcOptions.priceSelector) {
      price = calcOptions.priceSelector(selInput)
    }
    if (!price && this.priceSelector) {
      price = this.priceSelector.findPrice(selInput);
    }
    if (!price && !calcOptions?.allowNoPrices)
      throw new QuickPriceError(`No price found for bundle ${bundle.name}`, {
        extensions: { code: 'BAD_PRICELIST', bundle: bundle.name },
      });

    const priceList = this.getPriceList({
      date: selInput.date,
      priceListId: selInput.priceListId,
    });
    if (price && price.bundlePriceFromProduct) {
      const productLineItems = bundle.bundleItems
        .map(bi => {
          try {
            return this.getProductLineItem(day, {
              ...selInput,
              itemCount: bi.includedCount,
              bundleId: undefined,
              productId: bi.productId,
              selectorId: price?.selector?.id,
            }, null, calcOptions);
          } catch (err: any) {
            console.warn(err.message);
            return null;
          }
        })
        .filter(pli => pli) as ICalcLineItem[];
      const lineItem: ICalcLineItem = {
        day: day,
        sku: bundle.sku,
        name: bundle.name,
        bundle,
        selector: price.selector,
        count: selInput.itemCount,
        isIncluded: false,
        priceItem: productLineItems.find(pli => pli.priceItem === NO_PRICE) ? NO_PRICE : productLineItems.reduce((s, pli) => s + pli.priceItem * pli.count, 0),
        priceGross: 0,
        priceNet: productLineItems.find(pli => pli.priceNet === NO_PRICE) ? NO_PRICE : productLineItems.reduce((s, pli) => s + pli.priceNet, 0) * selInput.itemCount,
        components: [],
        taxes: [],
      };
      const productLineItemTaxes = productLineItems.reduce(
        (s, pli) => [
          ...s,
          ...pli.taxes.map(t => ({
            ...t,
            price: t.price === NO_PRICE ? NO_PRICE : t.price * selInput.itemCount,
          })),
        ],
        [] as ICalcTax[],
      );
      lineItem.priceGross = lineItem.priceNet === NO_PRICE || productLineItemTaxes.find(t => t.price === NO_PRICE) ? NO_PRICE : lineItem.priceNet + productLineItemTaxes.reduce((agg, t) => agg + t.price, 0)
      lineItem.taxes = mergeTaxes([], productLineItemTaxes);

      const productLineItemComponents = productLineItems.reduce(
        (s, pli) => [
          ...s,
          ...pli.components.map(plic => ({
            type: plic.type,
            price: plic.price === NO_PRICE ? NO_PRICE : plic.price * pli.count,
          })),
        ],
        [] as ICalcPriceComponent[],
      );
      lineItem.components = mergeComponents([], productLineItemComponents);

      return lineItem;
    } else {
      const lineItem: ICalcLineItem = {
        day: day,
        sku: bundle.sku,
        name: bundle.name,
        bundle,
        selector: price?.selector,
        count: selInput.itemCount,
        isIncluded: false,
        priceItem: price ? price.price || 0 : NO_PRICE,
        priceGross: 0,
        priceNet: 0,
        components: [],
        taxes: [],
      };

      if (price && bundle.components.length === 1) {
        price.components = [
          {
            price: price.price || 0,
            taxTypeId: bundle.components[0].taxTypeId,
          },
        ];
      }

      for (const bc of bundle.components) {
        const taxType = this.taxRateSelector?.getTaxType(bc.taxTypeId);
        const taxRate = this.taxRateSelector?.getTaxRate({
          country: this.hotel!.businessCountry,
          date: selInput.date,
          taxTypeId: bc.taxTypeId,
        });
        if (!taxRate || !taxType) {
          throw new QuickPriceError(`No tax rate found for bundle ${bundle.name}/${taxType?.name}`, {
            extensions: {
              code: 'BAD_PRICELIST',
              bundle: bundle.name,
              taxType: taxType?.name,
            },
          });
        }

        if (price) {
          const taxComponent = price.components?.find(c => c.taxTypeId === bc.taxTypeId);
          const taxComponentBasePrice = taxComponent ? taxComponent.price : 0;
          lineItem.components.push({
            type: taxType,
            price: taxComponentBasePrice,
          });

          if (taxComponentBasePrice !== NO_PRICE) {
            if (priceList!.isPricesNet) {
              lineItem.taxes.push({
                type: taxType,
                rate: taxRate,
                price: selInput.itemCount * roundPrice(taxComponentBasePrice * taxRate.rate),
              });
            } else {
              lineItem.taxes.push({
                type: taxType,
                rate: taxRate,
                price: selInput.itemCount * roundPrice(taxComponentBasePrice - taxComponentBasePrice / (1 + taxRate.rate)),
              });
            }
          } else {
            lineItem.taxes.push({
              type: taxType,
              rate: taxRate,
              price: NO_PRICE
            });
          }
        } else {
          lineItem.components.push({
            type: taxType,
            price: NO_PRICE,
          });
          lineItem.taxes.push({
            type: taxType,
            rate: taxRate,
            price: NO_PRICE,
          });
        }
      }
      lineItem.taxes = mergeTaxes([], lineItem.taxes);
      lineItem.components = mergeComponents([], lineItem.components);

      if (price && price.price !== NO_PRICE && !lineItem.taxes.find(t => t.price === NO_PRICE)) {
        if (priceList!.isPricesNet) {
          lineItem.priceNet = selInput.itemCount * (price.price || 0);
          lineItem.priceGross = lineItem.priceNet + lineItem.taxes.reduce((sum, t) => sum + t.price, 0);
        } else {
          lineItem.priceGross = selInput.itemCount * (price.price || 0);
          lineItem.priceNet = lineItem.priceGross - lineItem.taxes.reduce((sum, t) => sum + t.price, 0);
        }
      } else {
        lineItem.priceNet = NO_PRICE;
        lineItem.priceGross = NO_PRICE;
      }
      return lineItem;
    }
  }

  public getProductLineItem(day: number, selInput: PriceSelectorInput, price?: IPrice | null, calcOptions?: ICalcOptions | null) {
    const product = this.allProductsDb?.find(p => p.id === selInput.productId);
    if (!product) return null;
    
    if (!price && calcOptions && calcOptions.priceSelector) {
      price = calcOptions.priceSelector(selInput)
    }
    if (!price && this.priceSelector) {
      price = this.priceSelector.findPrice(selInput);
    }
    if (!price && !calcOptions?.allowNoPrices)
      throw new QuickPriceError(`No price found for product ${product.name}`, {
        extensions: { code: 'BAD_PRICELIST', product: product.name },
      });

    const priceList = this.getPriceList({
      date: selInput.date,
      priceListId: selInput.priceListId,
    });

    const lineItem: ICalcLineItem = {
      day: day,
      sku: product.sku,
      name: product.name,
      product,
      selector: price?.selector,
      count: selInput.itemCount,
      isIncluded: false,
      priceItem: price ? price.price || 0 : NO_PRICE,
      priceGross: 0,
      priceNet: 0,
      components: [],
      taxes: [],
    };

    const multi = product.isDeduction ? -1 : 1;

    if (price && product.components.length === 1) {
      price.components = [
        {
          price: price.price || 0,
          taxTypeId: product.components[0].taxTypeId
        }
      ];
    }

    for (const bc of product.components) {
      const taxType = this.taxRateSelector?.getTaxType(bc.taxTypeId);
      const taxRate = this.taxRateSelector?.getTaxRate({
        country: this.hotel!.businessCountry,
        date: selInput.date,
        taxTypeId: bc.taxTypeId,
      });
      if (!taxRate || !taxType) {
        throw new QuickPriceError(`No tax rate found for product ${product.name}/${taxType?.name}`, {
          extensions: {
            code: 'BAD_PRICELIST',
            product: product.name,
            taxType: taxType?.name,
          },
        });
      }

      if (price) {
        const taxComponent = price.components?.find(c => c.taxTypeId === bc.taxTypeId);
        const taxComponentBasePrice = taxComponent ? taxComponent.price : 0;
        lineItem.components.push({
          type: taxType,
          price: taxComponentBasePrice,
        });

        if (taxComponentBasePrice !== NO_PRICE) {
          if (priceList!.isPricesNet) {
            lineItem.taxes.push({
              type: taxType,
              rate: taxRate,
              price: multi * selInput.itemCount * roundPrice(taxComponentBasePrice * taxRate.rate),
            });
          } else {
            lineItem.taxes.push({
              type: taxType,
              rate: taxRate,
              price: selInput.itemCount * roundPrice(taxComponentBasePrice - taxComponentBasePrice / (1 + taxRate.rate)),
            });
          }
        } else {
          lineItem.taxes.push({
            type: taxType,
            rate: taxRate,
            price: NO_PRICE
          });
        }
      } else {
        lineItem.components.push({
          type: taxType,
          price: NO_PRICE,
        });
        lineItem.taxes.push({
          type: taxType,
          rate: taxRate,
          price: NO_PRICE,
        });
      }
    }
    lineItem.taxes = mergeTaxes([], lineItem.taxes);
    lineItem.components = mergeComponents([], lineItem.components);

    if (price && price.price !== NO_PRICE && !lineItem.taxes.find(t => t.price === NO_PRICE)) {
      if (priceList!.isPricesNet) {
        lineItem.priceNet = multi * selInput.itemCount * (price.price || 0);
        lineItem.priceGross = lineItem.priceNet + lineItem.taxes.reduce((sum, t) => sum + t.price, 0) * multi;
      } else {
        lineItem.priceGross = multi * selInput.itemCount * (price.price || 0);
        lineItem.priceNet = lineItem.priceGross - lineItem.taxes.reduce((sum, t) => sum + t.price, 0) * multi;
      }
    } else {
      lineItem.priceNet = NO_PRICE;
      lineItem.priceGross = NO_PRICE;
    }
    return lineItem;
  }

  public getFacilityLineItem(day: number, selInput: PriceSelectorInput, price?: IPrice | null, calcOptions?: ICalcOptions | null) {
    const facility = this.allFacilitiesDb?.find(f => f.id === selInput.facilityId);
    if (!facility) return null;

    if (!price && calcOptions && calcOptions.priceSelector) {
      price = calcOptions.priceSelector(selInput)
    }
    if (!price && this.priceSelector) {
      price = this.priceSelector.findPrice(selInput);
    }
    if (!price && !calcOptions?.allowNoPrices)
      throw new QuickPriceError(`No price found for facility ${facility.name}`, {
        extensions: { code: 'BAD_PRICELIST', facility: facility.name },
      });

    const priceList = this.getPriceList({
      date: selInput.date,
      priceListId: selInput.priceListId,
    });

    const taxRatesToApply: ITaxRate[] = [];

    for (const c of facility.components) {
      const taxRate = this.taxRateSelector?.getTaxRate({
        country: this.hotel!.businessCountry,
        date: selInput.date,
        taxTypeId: c.taxTypeId,
      });
      if (!taxRate) {
        const taxType = this.taxRateSelector?.getTaxType(c.taxTypeId)!;
        throw new QuickPriceError(`No tax rate found for facility ${facility.name}/${taxType.name}`, {
          extensions: {
            code: 'BAD_PRICELIST',
            facility: facility.name,
            taxType: taxType.name,
          },
        });
      }
      taxRatesToApply.push(taxRate);
    }

    const lineItem: ICalcLineItem = {
      day: day,
      sku: facility.sku,
      name: facility.name,
      facility,
      selector: price?.selector,
      count: selInput.itemCount,
      isIncluded: false,
      priceItem: price ? price.price || 0 : NO_PRICE,
      priceGross: 0,
      priceNet: 0,
      components: [],
      taxes: [],
    };

    if (price && facility.components.length === 1) {
      price.components = [
        {
          price: price.price || 0,
          taxTypeId: facility.components[0].taxTypeId,
        },
      ];
    }

    for (const bc of facility.components) {
      const taxType = this.taxRateSelector?.getTaxType(bc.taxTypeId);
      const taxRate = this.taxRateSelector?.getTaxRate({
        country: this.hotel!.businessCountry,
        date: selInput.date,
        taxTypeId: bc.taxTypeId,
      });
      if (!taxRate || !taxType) {
        throw new QuickPriceError(`No tax rate found for facility ${facility.name}/${taxType?.name}`, {
          extensions: {
            code: 'BAD_PRICELIST',
            facility: facility.name,
            taxType: taxType?.name,
          },
        });
      }
      if (price) {
        const taxComponent = price.components?.find(c => c.taxTypeId === bc.taxTypeId);
        const taxComponentBasePrice = taxComponent ? taxComponent.price : 0;

        lineItem.components.push({
          type: taxType,
          price: taxComponentBasePrice,
        });

        if (taxComponentBasePrice !== NO_PRICE) {
          if (priceList!.isPricesNet) {
            lineItem.taxes.push({
              type: taxType,
              rate: taxRate,
              price: selInput.itemCount * roundPrice(taxComponentBasePrice * taxRate.rate),
            });
          } else {
            lineItem.taxes.push({
              type: taxType,
              rate: taxRate,
              price: selInput.itemCount * roundPrice(taxComponentBasePrice - taxComponentBasePrice / (1 + taxRate.rate)),
            });
          }
        } else {
          lineItem.taxes.push({
            type: taxType,
            rate: taxRate,
            price: NO_PRICE
          });
        }
      } else {
        lineItem.components.push({
          type: taxType,
          price: NO_PRICE,
        });
        lineItem.taxes.push({
          type: taxType,
          rate: taxRate,
          price: NO_PRICE,
        });
      }
    }
    lineItem.taxes = mergeTaxes([], lineItem.taxes);
    lineItem.components = mergeComponents([], lineItem.components);

    if (price && price.price !== NO_PRICE && !lineItem.taxes.find(t => t.price === NO_PRICE)) {
      if (priceList!.isPricesNet) {
        lineItem.priceNet = selInput.itemCount * (price.price || 0);
        lineItem.priceGross = lineItem.priceNet + lineItem.taxes.reduce((sum, t) => sum + t.price, 0);
      } else {
        lineItem.priceGross = selInput.itemCount * (price.price || 0);
        lineItem.priceNet = lineItem.priceGross - lineItem.taxes.reduce((sum, t) => sum + t.price, 0);
      }
    } else {
      lineItem.priceNet = NO_PRICE
      lineItem.priceGross = NO_PRICE
    }
    return lineItem;
  }
}
