import _ from 'lodash';
import moment from 'moment';
import { EPricing_FacilityLookupCode, EPricing_FacilityPayment, EPricing_ProductLookupCode, EPricing_ProductPayment, ICalcLineItem, ICalcOptions, ICalcPriceOutput, IFacility, IProduct, IProductBundle, IQuickPriceDay, IQuickPriceInput, IServiceTypeFacility, IServiceTypeProduct, mergeLineItem, mergeTaxes, NO_PRICE, QuickPriceCalculatorBase, QuickPriceError, sumTotalPrices } from './quickprice';

export interface IQuickPriceInputLister extends IQuickPriceInput<IQuickPriceDayLister> {
}

export interface IQuickPriceDayLister extends IQuickPriceDay {
  occupancy: {
    from: string;
    to: string;
  }[];
  overnightRoomsEZ: number;
  overnightRoomsDZ: number;
  serviceTypes: IServiceTypeConfiguration[];
  addons: IQuickPriceDayAddonLister[];
}

export interface IServiceTypeConfiguration {
  serviceTypeSku: string;
  guestsCount: number;
}

export interface IQuickPriceDayAddonLister {
  sku: string;
  count: number;
}

export type QuickPriceInputTypeLister = IQuickPriceInputLister
export type CalcPriceOutputTypeLister = ICalcPriceOutput;
export type CalcLineItemTypeLister = ICalcLineItem;
export type QuickPriceCalculatorTypeLister = QuickPriceCalculatorLister;

export const isOccupancyFullDay = (occ: { from: string, to: string }) => {
  try {
    const froms = occ.from ? occ.from.split(':', 2).map(p => parseInt(p)) : [8, 0]
    const tos = occ.to ? occ.to.split(':', 2).map(p => parseInt(p)) : [18, 0]

    //more than four hours counts as full day
    return tos[0] - froms[0] > 4
  } catch (err) {
    console.warn('isOccupancyFullDay failed to get occupancy', err)
    return true
  }
}


export class QuickPriceCalculatorLister extends QuickPriceCalculatorBase<IQuickPriceDayLister, IQuickPriceInputLister, ICalcOptions, ICalcPriceOutput> {
  public getSKUIncludedInDayServiceTypes(qi: IQuickPriceInputLister, day: number, addons: boolean = false) {
    const serviceTypesConfigs = this._getDayServiceTypeConfigs(qi, day)

    const serviceTypeIncludedSkus = _.uniq(serviceTypesConfigs.services.flatMap(stc => this.getSKUIncludedInServiceType(stc.serviceType.sku, addons)))
    return serviceTypeIncludedSkus
  }

  private _getDayServiceTypeConfigs = (input: IQuickPriceInputLister, day: number) => {
    const dayConfig = input.days.find(d => d.day === day);
    if (dayConfig)
      return {
        totalGuests: dayConfig.serviceTypes.reduce((agg, st) => agg + st.guestsCount, 0),
        roomsCountEZ: dayConfig.overnightRoomsEZ,
        roomsCountDZ: dayConfig.overnightRoomsDZ,
        services: (this.allServiceTypesDb || []).map(serviceType => {
          const ist = dayConfig.serviceTypes.find(p => p.serviceTypeSku === serviceType.sku)
          return { serviceTypeSku: serviceType.sku, guestsCount: ist?.guestsCount || 0, serviceType };
        })
      }
    else
    return {
      totalGuests: 0,
      roomsCountEZ: 0,
      roomsCountDZ: 0,
      services: (this.allServiceTypesDb || []).map(serviceType => {
        return { serviceTypeSku: serviceType.sku, guestsCount: 0, serviceType };
      })
    }
  }

  public calculatePrice(input: IQuickPriceInputLister, calcOptions?: ICalcOptions): ICalcPriceOutput {
    const startMom = moment(input.startDate);
    const endMom = moment(input.endDate);

    const days = endMom.diff(startMom, 'days') + 1;
    for (let dayNum = 0; dayNum < days; dayNum++) {
      if (input.days.findIndex(d => d.day === dayNum) < 0) {
        throw new QuickPriceError(`No selection found for day ${dayNum}`, {
          extensions: { code: 'BAD_INPUT' },
        });
      }
    }

    const initialPriceList = this.getPriceList({
      date: startMom.toDate()
    });

    const allProductsDb = this.allProductsDb!;
    const allFacilitiesDb = this.allFacilitiesDb!;
    const allBundlesDb = this.allBundlesDb!;

    const addonsOnly = !(calcOptions?.addonsOnly === false || calcOptions?.addonsOnly === undefined)

    //TODO check minVisitors/maxVisitors etc

    const result: ICalcPriceOutput = {
      priceList: initialPriceList,
      hotel: this.hotel!,
      totalPriceGross: 0.0,
      totalPriceNet: 0.0,
      totalTaxes: 0.0,
      taxes: [],
      lineItems: [],
    };

    const _isFullDay = (day?: IQuickPriceDayLister) => day === undefined || day.occupancy.length === 0 || day.occupancy.findIndex(o => isOccupancyFullDay(o)) >= 0;
    const _getForCode = <TItem extends { bundle?: IProductBundle, product?: IProduct, facility?: IFacility }>(items: TItem[], lookupCode: string) => items.find(item => item.bundle ? item.bundle.lookupCode === lookupCode : item.product ? item.product.lookupCode === lookupCode : item.facility ? item.facility.lookupCode === lookupCode : false)
    const _getAddonForCode = (lookupCode: string) => {
      for (const st of this.allServiceTypesDb || []) {
        const addonFacility = st.addons.find(a => a.facility && allFacilitiesDb.find(f => f.id === a.facility!.id)?.lookupCode === lookupCode)
        if (addonFacility) return addonFacility

        const addonProduct = st.addons.find(a => a.product && allProductsDb.find(p => p.id === a.product!.id)?.lookupCode === lookupCode)
        if (addonProduct) return addonProduct
      }
      return null
    }

    const _filterServiceTypeProductsByPayment = (services: ReturnType<QuickPriceCalculatorLister['_getDayServiceTypeConfigs']>['services'], recurring?: string) => {
      const productConfigs: {
        products: (Omit<IServiceTypeProduct, 'product' | 'bundle'> & { product?: IProduct, bundle?: IProductBundle })[];
        guestsCount: number;
      }[] = [];

      for (const st of services) {
        const products = st.serviceType.products.map(sp => {
          if (sp.product) {
            const p = allProductsDb.find(pd => pd.id === sp.product!.id);
            return { ...sp, product: p, bundle: undefined }
          }
          if (sp.bundle) {
            const b = allBundlesDb.find(pd => pd.id === sp.bundle!.id);
            return { ...sp, product: undefined, bundle: b }
          }
        }).filter(sp => {
          if (sp && sp.product && (recurring ? sp.product.recurring === recurring : true)) return true
          if (sp && sp.bundle && (recurring ? sp.bundle.recurring === recurring : true)) return true
        }).map(sp => sp!)
        productConfigs.push({ products, guestsCount: st.guestsCount });
      }
      return productConfigs.flatMap(pc => pc.products.map(p => ({ ...p, recurring, guestsCount: pc.guestsCount })))
    }
    const _filterServiceTypeFacilitiesByPayment = (services: ReturnType<QuickPriceCalculatorLister['_getDayServiceTypeConfigs']>['services'], recurring?: string) => {
      const facilityConfigs: {
        facilities: (Omit<IServiceTypeFacility, 'facility'> & { facility: IFacility })[];
        guestsCount: number;
      }[] = [];

      for (const st of services) {
        const facilities = st.serviceType.facilities.map(sf => {
          const f = allFacilitiesDb.find(fd => fd.id === sf.facility.id)!;
          return { ...sf, facility: f }
        }).filter(sf => sf.facility && (recurring ? sf.facility.recurring === recurring : true))
        facilityConfigs.push({ facilities, guestsCount: st.guestsCount });
      }

      return facilityConfigs.flatMap(fc => fc.facilities.map(p => ({ ...p, recurring, guestsCount: fc.guestsCount })));
    }

    for (let dayNum = -1; dayNum < days; dayNum++) {
      const dayMom = moment(startMom).add(dayNum, 'days');
      const day = input.days.find(d => d.day === dayNum);

      const dayServiceTypes = this._getDayServiceTypeConfigs(input, dayNum);
      const priceList = this.getPriceList({ date: dayMom.toDate() });

      const isFullDay = _isFullDay(day)
      const isPrevDay = dayNum === -1
      const isDepartureDay = days > 1 && dayNum === days - 1
      const isSeminarDay = !isPrevDay && !isDepartureDay 

      const _addItem = (item: { bundleId?: number, productId?: number, facilityId?: number }, itemCount: number, addCount: boolean) => {
        if (item.bundleId) {
          const lineItem = this.getBundleLineItem(dayNum, {
            priceListId: priceList.id,
            bundleId: item.bundleId,
            guestCount: dayServiceTypes.totalGuests,
            itemCount: itemCount,
            date: dayMom.toDate()
          }, null, calcOptions);
          if (lineItem) mergeLineItem(result, lineItem, addCount);
        }
        if (item.productId) {
          const lineItem = this.getProductLineItem(dayNum, {
            priceListId: priceList.id,
            productId: item.productId,
            guestCount: dayServiceTypes.totalGuests,
            itemCount: itemCount,
            date: dayMom.toDate(),
          }, null, calcOptions);
          if (lineItem) mergeLineItem(result, lineItem, addCount);
        }
        if (item.facilityId) {
          const lineItem = this.getFacilityLineItem(dayNum, {
            priceListId: priceList.id,
            facilityId: item.facilityId,
            guestCount: dayServiceTypes.totalGuests,
            itemCount: itemCount,
            date: dayMom.toDate(),
          }, null, calcOptions);
          if (lineItem) mergeLineItem(result, lineItem, addCount);          
        }
      }

      if (!addonsOnly) {
        const _isRoom = (lookupCode?: string | null) => lookupCode && (lookupCode === EPricing_ProductLookupCode.ROOM_EZ || lookupCode === EPricing_ProductLookupCode.ROOM_DZ)
  
        const recurringProducts = [
          ..._filterServiceTypeProductsByPayment(dayServiceTypes.services, EPricing_ProductPayment.BYGUESTANDDAY),
          ..._filterServiceTypeProductsByPayment(dayServiceTypes.services, EPricing_ProductPayment.BYROOMANDDAY),
          ..._filterServiceTypeProductsByPayment(dayServiceTypes.services, EPricing_ProductPayment.BYCOUNT),
          ..._filterServiceTypeProductsByPayment(dayServiceTypes.services, EPricing_ProductPayment.BYGUEST),
        ];
  
        const _doDay = (ftProducts: (r: typeof recurringProducts[0]) => boolean, ftRooms: (r: typeof recurringProducts[0]) => boolean) => {
          const products = recurringProducts.filter(p => p.guestsCount > 0 && ftProducts(p) && !_isRoom(p.bundle?.lookupCode || p.product?.lookupCode));
          for (const p of products) {
            if (p.bundle) _addItem({ bundleId: p.bundle.id }, p.guestsCount, p.recurring !== EPricing_ProductPayment.BYCOUNT)
            else if (p.product) _addItem({ productId: p.product.id }, p.guestsCount, p.recurring !== EPricing_ProductPayment.BYCOUNT)
          }
          const roomEzRecurring = _getForCode(recurringProducts.filter(p => ftRooms(p)), EPricing_ProductLookupCode.ROOM_EZ) || _getForCode(recurringProducts.filter(p => p.isForRoom), EPricing_ProductLookupCode.ROOM_EZ)
          const roomDzRecurring = _getForCode(recurringProducts.filter(p => ftRooms(p)), EPricing_ProductLookupCode.ROOM_DZ) || _getForCode(recurringProducts.filter(p => p.isForRoom), EPricing_ProductLookupCode.ROOM_DZ)

          if (dayServiceTypes.roomsCountEZ > 0) {
            if (roomEzRecurring && roomEzRecurring.bundle) _addItem({ bundleId: roomEzRecurring.bundle.id }, dayServiceTypes.roomsCountEZ, true)
            else if (roomEzRecurring && roomEzRecurring.product) _addItem({ productId: roomEzRecurring.product.id }, dayServiceTypes.roomsCountEZ, true)
          }
          if (dayServiceTypes.roomsCountDZ > 0) {
            if (roomDzRecurring && roomDzRecurring.bundle) _addItem({ bundleId: roomDzRecurring.bundle.id }, dayServiceTypes.roomsCountDZ * 2, true)
            else if (roomDzRecurring && roomDzRecurring.product) _addItem({ productId: roomDzRecurring.product.id }, dayServiceTypes.roomsCountDZ * 2, true)
            //Fallback: Einzelzimmer buchen
            else if (roomEzRecurring && roomEzRecurring.bundle) _addItem({ bundleId: roomEzRecurring.bundle.id }, dayServiceTypes.roomsCountDZ * 2, true)
            else if (roomEzRecurring && roomEzRecurring.product) _addItem({ productId: roomEzRecurring.product.id }, dayServiceTypes.roomsCountDZ * 2, true)
          }
        }

        if (isPrevDay) _doDay(r => r.isForPrevday, r => r.isForPrevday)
        else if (isSeminarDay) _doDay(r => isFullDay ? r.isForDayVisitor : r.isForSemidayVisitor, r => isFullDay ? r.isForOvernight : r.isForSemidayOvernight)
        else if (isDepartureDay) _doDay(r => isFullDay ? r.isForDeparture : r.isForSemidayDeparture, r => r.isForNextday)
      }

      if (day && day.addons && day.addons.length > 0) {
        for (const addon of day.addons) {
          const addonProduct = allProductsDb.find(p => p.sku === addon.sku)
          if (addonProduct) {
            addon.sku = addonProduct.sku;
            _addItem({ productId: addonProduct.id }, addon.count || 1, true)
            /*if (addonProduct.recurring === EPricing_ProductPayment.BYGUESTANDDAY || addonProduct.recurring === EPricing_ProductPayment.BYGUEST) {
              _addItem({ productId: addonProduct.id }, (addon.count || 1) * dayServiceTypes.totalGuests)
            } else if (addonProduct.recurring === EPricing_ProductPayment.BYROOMANDDAY || addonProduct.recurring === EPricing_ProductPayment.BYROOM) {
              _addItem({ productId: addonProduct.id }, (addon.count || 1) * Math.ceil(day.overnightRoomsEZ + day.overnightRoomsDZ)))
            } else if (addonProduct.recurring === EPricing_ProductPayment.BYCOUNT) {
              _addItem({ productId: addonProduct.id }, addon.count || 1)
            }*/
          }
        }
      }

      if (dayNum >= 0) {

        if (!addonsOnly) {
          const recurringFacilities = _filterServiceTypeFacilitiesByPayment(dayServiceTypes.services, EPricing_FacilityPayment.BYDAY);

          const byDayFacilities = recurringFacilities.filter(p => (isFullDay ? p.isForDay : p.isForSemiday));

          for (const f of byDayFacilities) {
            const lineItem: ICalcLineItem = {
              day: dayNum,
              sku: f.facility.sku,
              name: f.facility.name,
              facility: f.facility,
              count: f.includedCount,
              isIncluded: true,
              priceItem: 0,
              priceGross: 0,
              priceNet: 0,
              components: [],
              taxes: [],
            };
            mergeLineItem(result, lineItem, false);
          }
        }

        const dailyFacilities = _filterServiceTypeFacilitiesByPayment(dayServiceTypes.services);

        const seminarroomFacility = _getForCode(dailyFacilities.filter(f => f.isForDay), EPricing_FacilityLookupCode.SEMINARROOM_FULLDAY)
        const seminarroomSemidayFacility = _getForCode(dailyFacilities.filter(f => f.isForSemiday), EPricing_FacilityLookupCode.SEMINARROOM_SEMIDAY)
        const addonSeminarroomFacility = _getAddonForCode(EPricing_FacilityLookupCode.SEMINARROOM_FULLDAY)
        const addonSeminarroomSemidayFacility = _getAddonForCode(EPricing_FacilityLookupCode.SEMINARROOM_SEMIDAY)

        const addonFacilities = [...(day?.addonFacilities || [])];

        if ((day?.occupancy || []).length > 0) {
          const bookedFullCount = (day?.occupancy || []).filter(o => isOccupancyFullDay(o)).length;
          const includedFullCount = (seminarroomFacility && seminarroomFacility.includedCount) || 0;
          const addonFullCount = Math.max(0, bookedFullCount - includedFullCount);
          if (addonSeminarroomFacility && addonSeminarroomFacility.facility && addonFullCount > 0) {
            addonFacilities.push({
              sku: addonSeminarroomFacility.facility!.sku,
              count: addonFullCount,
            });
          }
          const remainingFullCountIncluded = Math.max(0, includedFullCount - bookedFullCount);

          const bookedSemiCount = (day?.occupancy || []).filter(o => !isOccupancyFullDay(o)).length;
          const includedSemiCount = (seminarroomSemidayFacility && seminarroomSemidayFacility.includedCount) || 0;
          const addonSemiCount = Math.max(0, bookedSemiCount - includedSemiCount - remainingFullCountIncluded);
          if (addonSeminarroomSemidayFacility && addonSeminarroomSemidayFacility.facility && addonSemiCount > 0) {
            addonFacilities.push({
              sku: addonSeminarroomSemidayFacility.facility!.sku,
              count: addonSemiCount,
            });
          }
        }
        for (const addon of addonFacilities) {
          const addonFacility = addon.sku ? allFacilitiesDb.find(f => f.sku === addon.sku)
            : addon.lookupCode ? allFacilitiesDb.find(f => f.lookupCode === addon.lookupCode)
            : null;
          if (addonFacility) {
            addon.sku = addonFacility.sku;
            if (addonFacility.recurring === EPricing_FacilityPayment.BYGUEST) {
              _addItem({ facilityId: addonFacility.id }, (addon.count || 1) * dayServiceTypes.totalGuests, true)
            } else if (addonFacility.recurring === EPricing_FacilityPayment.BYDAY || addonFacility.recurring === EPricing_FacilityPayment.BYCOUNT) {
              _addItem({ facilityId: addonFacility.id }, addon.count || 1, false)
            }
          }
        }
      }
    }

    /*
    TODO: BYCOUNT und BYGUEST und BYROOM
    const allDaysGuestsCount = input.days.reduce(
      (acc, day) => {
        acc += day.totalGuests;
        return acc;
      },
      0,
    );
  
    if (allDaysGuestsCount > 0) {
      const byCountProducts = _filterServiceTypeProductsByPayment(0, EPricing_ProductPayment.BYCOUNT);

      for (const p of byCountProducts) {
        const firstDay = input.days.find(d => d.day === 0)!;
        const priceList = this.getPriceList({ date: startMom.toDate() });

        if (p.bundle) {
          const lineItem = this.getBundleLineItem(0, {
            priceListId: priceList.id,
            bundleId: p.bundle.id,
            guestCount: firstDay.totalGuests,
            itemCount: 1,
            date: startMom.toDate(),
          }, null, calcOptions);
          if (lineItem) mergeLineItem(result, lineItem);
        }
        if (p.product) {
          const lineItem = this.getProductLineItem(0, {
            priceListId: priceList.id,
            productId: p.product.id,
            guestCount: firstDay.totalGuests,
            itemCount: 1,
            date: startMom.toDate(),
          }, null, calcOptions);
          if (lineItem) mergeLineItem(result, lineItem);
        }
      }
    }

    const byGuestProducts = [..._filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYGUEST), ..._filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYROOM)];
    for (const p of byGuestProducts) {
      const firstDay = input.days.find(d => d.day === 0)!;
      const priceList = this.getPriceList({ date: startMom.toDate() });

      if (p.bundle) {
        const lineItem = this.getBundleLineItem(0, {
          priceListId: priceList.id,
          bundleId: p.bundle.id,
          guestCount: firstDay.totalGuests,
          itemCount: firstDay.totalGuests,
          date: startMom.toDate(),
        }, null, calcOptions);
        if (lineItem) mergeLineItem(result, lineItem);
      }
      if (p.product) {
        const lineItem = this.getProductLineItem(0, {
          priceListId: priceList.id,
          productId: p.product.id,
          guestCount: firstDay.totalGuests,
          itemCount: firstDay.totalGuests,
          date: startMom.toDate(),
        }, null, calcOptions);
        if (lineItem) mergeLineItem(result, lineItem);
      }
    }
    */

    /*
    TODO: BYCOUNT und BYGUEST

    const byCountFacilities = _filterServiceTypeFacilitiesByPayment(dayServiceTypes.services, EPricing_FacilityPayment.BYCOUNT);

    for (const f of byCountFacilities) {
      const facility = allFacilitiesDb.find(fd => fd.id === f.facility.id)!;
      const lineItem: CalcLineItem<FacilityType, ProductType, ProductBundleType> = {
        day: 0,
        sku: facility.sku,
        name: facility.name,
        facility: facility,
        count: f.includedCount || 1,
        isIncluded: true,
        priceItem: 0,
        priceGross: 0,
        priceNet: 0,
        components: [],
        taxes: [],
      };
      mergeLineItem(result, lineItem);
    }

    const byGuestFacilities = _filterServiceTypeFacilitiesByPayment(EPricing_FacilityPayment.BYGUEST);

    for (const f of byGuestFacilities) {
      const firstDay = input.days.find(d => d.day === 0)!;
      const facility = allFacilitiesDb.find(fd => fd.id === f.facility.id)!;
      const lineItem: CalcLineItem<FacilityType, ProductType, ProductBundleType> = {
        day: 0,
        sku: facility.sku,
        name: facility.name,
        facility: facility,
        count: (f.includedCount || 1) * firstDay.totalGuests,
        isIncluded: true,
        priceItem: 0,
        priceGross: 0,
        priceNet: 0,
        components: [],
        taxes: [],
      };
      mergeLineItem(result, lineItem);
    }
    */

    sumTotalPrices(result);
    result.lineItems.sort((a, b) => (a.day === null ? -1 : b.day === null ? 1 : a.day - b.day));
    return result;
  }
}
