import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { Button, Grid, CircularProgress, Divider, Switch, Alert } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import PlusIcon from '@mui/icons-material/Add';

import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { yupResolver } from '@hookform/resolvers/yup';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { hintValidationAlert } from 'helper/form';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputDate } from 'components/form/FormInputDate';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';

import SimpleAccordion from 'components/SimpleAccordion';
import { EditorAccordion } from 'components/template_editor/EditorAccordion';
import { ContentBlockFormType, OfferVersionFormType, QuickPriceCalculatorContext, TaxesFormType, validationSchema } from 'components/template_editor/context';
import { HTMLOfferRenderer } from 'components/template_editor/HTMLRenderer';

import {
  OFFERVERSION_VIEW_QUERY,
  OFFERVERSION_QUICKPRICE_QUERY,
  OFFERVERSION_PRODUCTTEXT_QUERY,
  OFFERVERSION_UPSERT_MUTATION,
  REFETCH_OFFER_QUERIES,
  EVICT_OFFER_QUERIES,
} from './gql';
import {
  EOfferVersionContentBlockType,
  EContentProductDetailPosition,
  WLOfferVersionListOutput,
  EOfferRoomOccupancy,
  EOfferRoomSeating,
  ViewWlOfferVersionQuery,
  EOfferSource,
  ListTaxTypesQuery,
  ListFullTaxTypesQuery,
  EOfferVersionContentBlockFormFieldType,
} from '__generated__/graphql';

import { NO_PRICE } from '../../semshared/pricelist/quickprice';
import { ProductTextSelector, ProductTextSelectorType } from '../../semshared/pricelist/producttextselector';
import { calculateTotals, mapOfferStatusToDocumentType } from '../../semshared/offer/offer';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { RedirectError } from 'pages/error';
import { FormInputCountryDropdown } from 'components/form/FormInputDropdown';
import { TAXTYPES_LIST_FULL_QUERY } from 'pages/prices/gql';
import { formatPrice } from 'components/Price';
import { QuickPriceCalculatorLister, QuickPriceCalculatorTypeLister } from 'semshared/pricelist/quickprice_lister';
import { QuickPriceCalculatorTypeWidget, QuickPriceCalculatorWidget } from 'semshared/pricelist/quickprice_widget';

interface OfferVersionProps {
  versionId: number;
  offerId: number;
  offerSource: EOfferSource;
  disabled: boolean;
  canChangeItems: boolean;
  canChangeStructure: boolean;
  canChangePrices: boolean;
  canChangeClientData: boolean;
  canSeeClientData: boolean;
  submitTrigger: number;
  savePrices: boolean;
  onShowOfferVersion: (id: number) => void;
  onChangeIsValid: (isValid: boolean) => void;
  onChangeCanSaveState: (canSave: boolean) => void;
}

interface OfferVersionFormProps extends OfferVersionProps {
  data: NonNullable<ViewWlOfferVersionQuery['viewWlOfferVersion']>;
  quickPriceCalculator: QuickPriceCalculatorTypeWidget | QuickPriceCalculatorTypeLister;
  productTexts: ProductTextSelectorType;
  taxTypes: ListFullTaxTypesQuery['listTaxTypes']
}

function OfferVersionForm(props: OfferVersionFormProps) {
  const dispatch = useDispatch();
  const [upsertMutateFunction] = useMutation(OFFERVERSION_UPSERT_MUTATION);

  const [renderCycle, setRenderCycle] = useState(0);
  const [submitTrigger, setSubmitTrigger] = useState(props.submitTrigger)

  const toFormSchema = (obj: NonNullable<ViewWlOfferVersionQuery['viewWlOfferVersion']>): OfferVersionFormType => ({
    id: obj.id,
    title: obj.title,
    filename: obj.filename,
    version: obj.version,
    offerDate: obj.offerDate,
    startDate: obj.startDate,
    additionalInfo1: obj.additionalInfo1,
    additionalInfo2: obj.additionalInfo2,
    additionalInfo3: obj.additionalInfo3,
    agreedCancellationWaiveNet: obj.agreedCancellationWaiveNet,
    manualCancellationWaiveNet: obj.manualCancellationWaiveNet,
    contentBlocks: obj.contentBlocks.map(cb => ({
      ...cb,
      formFields: cb.formFields.map(ff => ({
        name: ff.name,
        type: ff.type,
        label: ff.label,
        sequence: ff.sequence,
        required: ff.required,
        value: ff.value,
        id: ff.id,
        placeholder: ff.placeholder
      })),
      day: cb.lineItems[0] ? cb.lineItems[0].day : 0,
      cancellationRule: cb.cancellationRule ? {
        daysToEvent: cb.cancellationRule.daysToEvent,
        rate: cb.cancellationRule.rate
      } : null,
      lineItems: cb.lineItems.map(li => ({
        ...li,
        priceItem: li.priceItem,
        priceNet: li.priceNet,
        priceGross: li.priceGross,
        components: li.components.map(c => ({
          taxTypeId: c.type.id,
          name: c.type.name,
          price: c.price
        })),
        taxes: li.taxes.map(t => ({
          taxTypeId: t.type.id,
          taxRateId: t.rate.id,
          name: t.type.name,
          rate: t.rate.rate,
          price: t.price
        })),
      })),
    })) as any,
    totalPriceNet: obj.totalPriceNet,
    totalPriceGross: obj.totalPriceGross,
    totalTax: (obj.totalPriceNet === NO_PRICE || obj.totalPriceGross === NO_PRICE) ? NO_PRICE : obj.totalPriceGross - obj.totalPriceNet,
    totalTaxes: obj.taxes.map(t => ({
      taxTypeId: t.type.id,
      taxRateId: t.rate.id,
      name: t.type.name,
      rate: t.rate.rate,
      price: t.price,
    })),
    pageOptions: {
      marginTop: obj.marginTop || 0,
      marginBottom: obj.marginBottom || 0,
      marginLeft: obj.marginLeft || 0,
      marginRight: obj.marginRight || 0,
      cssStyles: obj.cssStyles,
      headerTemplate: obj.headerTemplate,
      footerTemplate: obj.footerTemplate,
    },
    includeEmptyLineItems: obj.includeEmptyLineItems,
    client: props.canSeeClientData ? {
      ...obj.client,
    } : null,
    ...(obj.input ? {
      input: {
        ...obj.input,
        serviceType: obj.input.serviceType || null
      }
    } : { input: null })
  });

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema(props.data),
  });
  const {
    handleSubmit,
    control,
    trigger,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors: validationErrors, isValid, isDirty, isValidating, isSubmitting },
  } = form;

  const {
    fields: contentBlocksFields,
    remove: contentBlocksRemove,
    replace: contentBlocksReplace,
  } = useFieldArray({
    control,
    name: 'contentBlocks',
  });

  const [advanced, setAdvanced] = useState(false);
  const [showHtml, setShowHtml] = useState(props.data.offer.source === EOfferSource.WIDGET)
  const fullWidth = !showHtml

  const [switchOfferVersionId, setSwitchOfferVersionId] = useState(0);
  useEffect(() => {
    if (switchOfferVersionId > 0) props.onShowOfferVersion(switchOfferVersionId);
  }, [switchOfferVersionId]);

  useEffect(() => {
    if (props.disabled) return
    if (submitTrigger !== props.submitTrigger) {
      handleSubmit(onSubmit())()
      setSubmitTrigger(props.submitTrigger)
    }
  }, [props.submitTrigger])

  useEffect(() => {
    if (props.disabled) return
    props.onChangeIsValid(isValid)
    props.onChangeCanSaveState(isValid && !isSubmitting && !isValidating)
  }, [isSubmitting, isValidating, isValid]);

  const onSubmit = () => async (values: OfferVersionFormType) => {
    try {
      const res = await upsertMutateFunction({
        variables: {
          id: props.versionId,
          data: {
            additionalInfo1: values.additionalInfo1,
            additionalInfo2: values.additionalInfo2,
            additionalInfo3: values.additionalInfo3,
            hotelId: props.data.hotel.id,
            priceListId: props.data.priceList.id,
            version: values.version,
            title: values.title || null,
            filename: values.filename || null,
            offerDate: values.offerDate,
            startDate: values.startDate,
            headerTemplate: values.pageOptions.headerTemplate || null,
            footerTemplate: values.pageOptions.footerTemplate || null,
            marginTop: values.pageOptions.marginTop,
            marginBottom: values.pageOptions.marginBottom,
            marginLeft: values.pageOptions.marginLeft,
            marginRight: values.pageOptions.marginRight,
            includeEmptyLineItems: values.includeEmptyLineItems,
            cssStyles: values.pageOptions.cssStyles,
            contentBlocks: values.contentBlocks.map(cb => ({
              template: cb.template,
              sequence: cb.sequence,
              type: cb.type as EOfferVersionContentBlockType,
              formFields: (cb.formFields || []).map(ff => ({
                sequence: ff.sequence,
                type: ff.type  as EOfferVersionContentBlockFormFieldType,
                label: ff.label,
                name: ff.name,
                value: ff.value,
                required: ff.required,
              })),
              lineItems: (cb.lineItems || []).map(li => ({
                day: li.day,
                sku: li.sku || null,
                header: li.header,
                details: li.details || '',
                count: li.count,
                sequence: li.sequence,
                priceItem: li.priceItem,
                priceNet: li.priceNet,
                priceGross: li.priceGross,
                components: li.components.map(t => ({
                  price: t.price,
                  taxTypeId: t.taxTypeId,
                })),
                taxes: li.taxes.map(t => ({
                  price: t.price,
                  taxRateId: t.taxRateId
                })),
              })),
            })),
            totalPriceGross: values.totalPriceGross,
            totalPriceNet: values.totalPriceNet,
            taxes: values.totalTaxes.map(t => ({
              price: t.price,
              taxRateId: t.taxRateId
            })),
          },
          client: props.canChangeClientData && values.client ? {
            email: values.client.email,
            phone: values.client.phone,
            firstname: values.client.firstname,
            lastname: values.client.lastname,
            company: values.client.company,
            country: values.client.country,
            address: values.client.address,
            city: values.client.city,
            zip: values.client.zip,
            billingCompany: values.client.billingCompany,
            billingFirstname: values.client.billingFirstname,
            billingLastname: values.client.billingLastname,
            billingAddress: values.client.billingAddress,
            billingCity: values.client.billingCity,
            billingZip: values.client.billingZip,
            billingCountry: values.client.billingCountry,
          } : undefined,
          savePrices: props.savePrices
        },
        update: cache => EVICT_OFFER_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_OFFER_QUERIES(props.offerId, props.data.id),
      });
      reset(toFormSchema(res.data!.upsertOfferVersion as WLOfferVersionListOutput));
      dispatchMessage(dispatch, i18next.t('offerversion-saved'));
      setSwitchOfferVersionId(res.data!.upsertOfferVersion.id);
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  useEffect(() => {
    if (props.disabled) return
    trigger();
  }, [trigger]);

  watch(() => {
    setRenderCycle(renderCycle + 1);
  });

  const onLineItemSKUAdded = (cbIndex: number, sku: string) => {
    const contentBlock = getValues(`contentBlocks.${cbIndex}`);
    const lineItemDate = moment(getValues('startDate')).add(contentBlock.day!, 'days').toDate();

    try {
      const header = props.productTexts.getProductText({
        sku: sku,
        hotelId: props.data.hotel.id,
        position: EContentProductDetailPosition.HEADER,
        language: props.data.offer.language
      });
      const details = props.productTexts.getProductText({
        sku: sku,
        hotelId: props.data.hotel.id,
        position: EContentProductDetailPosition.DETAILS,
        language: props.data.offer.language
      });

      const calcLineItem = props.quickPriceCalculator.getSKULineItem(sku, { date: lineItemDate, itemCount: 1 });
      if (calcLineItem) {
        return {
          sku: calcLineItem.sku,
          count: calcLineItem.count,
          priceItem: calcLineItem.priceItem,
          priceGross: calcLineItem.priceGross,
          priceNet: calcLineItem.priceNet,
          day: contentBlock.day!,
          header: header || calcLineItem.name,
          details: details || '',
          sequence: 1000,
          components: calcLineItem.components.map(c => ({
            taxTypeId: c.type.id,
            name: c.type.name,
            price: c.price,
          })),
          taxes: calcLineItem.taxes.map(t => ({
            taxTypeId: t.type.id,
            taxRateId: t.rate.id,
            name: t.type.name,
            rate: t.rate.rate,
            price: t.price,
          })),
        };
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };
  const onLineItemFreeAdded = (cbIndex: number, taxTypeId: number) => {
    const contentBlock = getValues(`contentBlocks.${cbIndex}`);
    const lineItemDate = moment(getValues('startDate')).add(contentBlock.day!, 'days').toDate();

    try {
      const priceList = props.quickPriceCalculator.getPriceList({
        date: lineItemDate,
      });
      const calcLineItem = props.quickPriceCalculator.getFreeStyleLineItem(1, 0, priceList!.isPricesNet, taxTypeId);
      return {
        count: 1,
        priceItem: 0,
        priceGross: 0,
        priceNet: 0,
        day: contentBlock.day!,
        header: '',
        details: '',
        sequence: 1000,
        components: calcLineItem!.components.map(c => ({
          taxTypeId: c.type.id,
          name: c.type.name,
          price: c.price,
        })),
        taxes: calcLineItem!.taxes.map(t => ({
          taxTypeId: t.type.id,
          taxRateId: t.rate.id,
          name: t.type.name,
          rate: t.rate.rate,
          price: t.price,
        })),
      };
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const onTotalsChanged = () => {
    const { totalPriceNet, totalPriceGross, totalTaxes, totalTax } = calculateTotals(getValues())
    
    setValue('totalPriceNet', totalPriceNet);
    setValue('totalPriceGross', totalPriceGross);
    setValue('totalTax', totalTax);
    setValue('totalTaxes', _.orderBy(totalTaxes, [t => t.name], 'asc').map(t => ({
      ...t,
      taxTypeId: props.taxTypes.find(tt => tt.rates.findIndex(tr => tr.id === t.taxRateId) >= 0)!.id
    })));
    trigger();
  };

  const onLineItemPriceComponentChanged = (cbIndex: number, liIndex: number, componentIndex: number) => {
    const lineItem = getValues(`contentBlocks.${cbIndex}.lineItems.${liIndex}`);
    const priceItem = lineItem.components.reduce((s, c) => (s === NO_PRICE || c.price === NO_PRICE) ? NO_PRICE : s + c.price, 0);
    setValue(`contentBlocks.${cbIndex}.lineItems.${liIndex}.priceItem`, priceItem);
    trigger();
  };
  const onLineItemCopyPrices = (cbIndex: number, liIndex: number) => {
    const contentBlock = getValues(`contentBlocks.${cbIndex}`);
    const lineItem = getValues(`contentBlocks.${cbIndex}.lineItems.${liIndex}`);

    if (lineItem.sku) {
      const allContentBlocks = getValues('contentBlocks');
      for (const [acbIndex, acb] of allContentBlocks.entries()) {
        if (acb.type !== EOfferVersionContentBlockType.LINE_ITEMS) continue;
        const cbLineItems = getValues(`contentBlocks.${acbIndex}.lineItems`)
        for (const [aliIndex, ali] of (cbLineItems || []).entries()) {
          if (cbIndex === acbIndex && aliIndex === liIndex) continue
          if (ali.sku !== lineItem.sku) continue

          setValue(`contentBlocks.${acbIndex}.lineItems.${aliIndex}.priceItem`, lineItem.priceItem);
          lineItem.components.forEach((c, cindex) => setValue(`contentBlocks.${acbIndex}.lineItems.${aliIndex}.components.${cindex}`, { ...c }));
          setTimeout(() => onLineItemChanged(acbIndex, aliIndex), 0);
        }
      }
    }
  };
  const onLineItemChanged = (cbIndex: number, liIndex: number) => {
    const contentBlock = getValues(`contentBlocks.${cbIndex}`);
    const lineItem = getValues(`contentBlocks.${cbIndex}.lineItems.${liIndex}`);
    const lineItemDate = moment(getValues('startDate')).add(contentBlock.day!, 'days').toDate();

    // if ((lineItem.components.length === 1 && lineItem.priceItem === NO_PRICE) || (lineItem.components.length > 1 && lineItem.components.find(lic => lic.price === NO_PRICE))) return

    try {
      const priceList = props.quickPriceCalculator.getPriceList({
        date: lineItemDate,
      });

      let calcLineItem = null;
      if (lineItem.sku) {
        try {
          calcLineItem = props.quickPriceCalculator.getSKULineItem(lineItem.sku, { date: lineItemDate, itemCount: lineItem.count }, {
            price: lineItem.priceItem,
            bundlePriceFromProduct: false,
            components: lineItem.components.map(c => ({
              taxTypeId: c.taxTypeId,
              price: c.price,
            })),
          });
        } catch (err) {
          calcLineItem = props.quickPriceCalculator.getFreeStyleLineItem(
            lineItem.count,
            lineItem.priceItem,
            priceList!.isPricesNet,
            lineItem.taxes[0].taxTypeId,
          );
        }
      } else {
        calcLineItem = props.quickPriceCalculator.getFreeStyleLineItem(
          lineItem.count,
          lineItem.priceItem,
          priceList!.isPricesNet,
          lineItem.taxes[0].taxTypeId,
        );
      }

      if (calcLineItem) {
        setValue(`contentBlocks.${cbIndex}.lineItems.${liIndex}.priceGross`, calcLineItem.priceGross);
        setValue(`contentBlocks.${cbIndex}.lineItems.${liIndex}.priceNet`, calcLineItem.priceNet);
        setValue(`contentBlocks.${cbIndex}.lineItems.${liIndex}.components`,
          calcLineItem.components.map(c => ({
            taxTypeId: c.type.id,
            name: c.type.name,
            price: c.price,
          })),
        );
        setValue(
          `contentBlocks.${cbIndex}.lineItems.${liIndex}.taxes`,
          calcLineItem.taxes.map(t => ({
            taxTypeId: t.type.id,
            taxRateId: t.rate.id,
            name: t.type.name,
            rate: t.rate.rate,
            price: t.price,
          })),
        );
        trigger();
      }
      onTotalsChanged();
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };
  const onContentBlockDelete = (index: number) => {
    contentBlocksRemove(index);
    _reassignSequence(getValues('contentBlocks'));
    onTotalsChanged();
  };

  const _reassignSequence = (contentBlocks: ContentBlockFormType[]) => {
    contentBlocks.forEach((cb, index) => {
      cb.sequence = index + 1;
    });
    contentBlocksReplace(contentBlocks);
  };

  return (
    <QuickPriceCalculatorContext.Provider value={props.quickPriceCalculator}>
      <FormProvider {...form}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {showHtml && <Grid item sm={6}>
                <HTMLOfferRenderer
                  key={`HTMLRenderer_${renderCycle}`}
                  spaceId={props.data.space.id}
                  input={{
                    documentType: mapOfferStatusToDocumentType(props.data.offer.status, props.data.status),
                    date: moment(watch('offerDate')).toDate(),
                    refCode: props.data.offer.refCode,
                    startDate: moment(watch('startDate')).toDate(),
                    expirationDate: moment(watch('offerDate'))
                      .add(props.data.hotel.offerExpirationDays || 30, 'days')
                      .toDate(),
                    hotel: props.data.hotel,
                    serviceType: props.data.input?.serviceType,
                    client: props.canChangeClientData ? watch('client')! : props.data.client,
                    contentBlocks: watch('contentBlocks'),
                    pageOptions: watch('pageOptions'),
                    includeEmptyLineItems: watch('includeEmptyLineItems'),
                    totalPriceNet: watch('totalPriceNet'),
                    totalPriceGross: watch('totalPriceGross'),
                    totalTax: watch('totalTax'),
                    totalTaxes: watch('totalTaxes'),
                  }}
                />
              </Grid>}
              <Grid item sm={showHtml ? 6 : 12}>
                <UnsavedChangesPrompt isDirty={isDirty} />
                <Grid container spacing={3}>
                  {props.data.offer.source === EOfferSource.WIDGET && <>
                    <Grid item xs={6}>
                      <Switch checked={showHtml} onChange={() => setShowHtml(!showHtml)} />
                      {i18next.t('offertemplate-showhtml')}
                    </Grid>
                    <Grid item xs={6}>
                      <Switch checked={advanced} onChange={() => setAdvanced(!advanced)} />
                      {i18next.t('offertemplate-advanced')}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>}
                  <Grid item xs={12}>
                    <SimpleAccordion header={`${i18next.t('offertemplate-section-totals')}`} autoOpen>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {i18next.t('offertemplate-totals-net')}: {formatPrice(watch('totalPriceNet'), props.data.priceList.currency)}
                        </Grid>
                        <Grid item xs={12}>
                          {i18next.t('offertemplate-totals-taxes')}: {formatPrice(watch('totalTax'), props.data.priceList.currency)}
                        </Grid>
                        <Grid item xs={12}>
                          {i18next.t('offertemplate-totals-gross')}: {formatPrice(watch('totalPriceGross'), props.data.priceList.currency)}
                        </Grid>
                      </Grid>
                    </SimpleAccordion>
                  </Grid>
                  {advanced && <Grid item xs={12}>
                    <FormInputText textFieldProps={{
                      multiline: true,
                      rows: 4,
                    }} name="version" control={control} label={i18next.t('offertemplate-version')} required disabled={props.disabled} />
                  </Grid>}
                  <Grid item xs={12}>
                    <SimpleAccordion header={`${i18next.t('offertemplate-section-offer')}`}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={showHtml ? 6 : 3}>
                          <FormInputDate name="startDate" control={control} label={i18next.t('offertemplate-startdate')} required disabled={props.disabled || !props.canChangeItems} />
                        </Grid>
                        <Grid item xs={12} sm={showHtml ? 6 : 3}>
                          <FormInputDate name="offerDate" control={control} label={i18next.t('offertemplate-offerdate')} required disabled={props.disabled} />
                        </Grid>
                      </Grid>
                    </SimpleAccordion>
                  </Grid>
                  {props.canSeeClientData && <Grid item xs={12}>
                    <SimpleAccordion
                      header={`${i18next.t('offertemplate-section-client')}`}
                      icon={<>{!props.disabled && props.canChangeClientData && hintValidationAlert(validationErrors.client)}</>}
                    >
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={fullWidth ? 6 : 12}>
                            <FormInputText name="client.email" control={control} label={i18next.t('offertemplate-client-email')} required disabled={props.disabled || !props.canChangeClientData} />
                          </Grid>
                          <Grid item xs={12} sm={fullWidth ? 6 : 12}>
                            <FormInputText name="client.phone" control={control} label={i18next.t('offertemplate-client-phone')} required={false} disabled={props.disabled || !props.canChangeClientData} />
                          </Grid>
                          <Grid item xs={12} sm={fullWidth ? 6 : 12}>
                            <SimpleAccordion
                              header={`${i18next.t('offertemplate-section-client-company')}`}
                              icon={
                                <>
                                  {!props.disabled && props.canChangeClientData && validationErrors.client &&
                                    hintValidationAlert(
                                      _.omit(validationErrors.client, [
                                        'email',
                                        'phone',
                                        ...Object.keys(validationErrors.client).filter(k => k.startsWith('billing')),
                                      ]),
                                    )}
                                </>
                              }
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <FormInputText
                                    name="client.company"
                                    control={control}
                                    label={i18next.t('offertemplate-client-company-company')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInputText
                                    name="client.firstname"
                                    control={control}
                                    label={i18next.t('offertemplate-client-company-firstname')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInputText
                                    name="client.lastname"
                                    control={control}
                                    label={i18next.t('offertemplate-client-company-lastname')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormInputText
                                    name="client.address"
                                    control={control}
                                    label={i18next.t('offertemplate-client-company-address')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInputText name="client.zip" control={control} label={i18next.t('offertemplate-client-company-zip')} required disabled={props.disabled || !props.canChangeClientData} />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInputText name="client.city" control={control} label={i18next.t('offertemplate-client-company-city')} required disabled={props.disabled || !props.canChangeClientData} />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInputCountryDropdown
                                    name="client.country"
                                    control={control}
                                    label={i18next.t('offertemplate-client-company-country')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                              </Grid>
                            </SimpleAccordion>
                          </Grid>
                          <Grid item xs={12} sm={fullWidth ? 6 : 12}>
                            <SimpleAccordion
                              header={`${i18next.t('offertemplate-section-client-billing')}`}
                              icon={
                                <>
                                  {!props.disabled && props.canChangeClientData && validationErrors.client &&
                                    hintValidationAlert(
                                      _.omit(
                                        validationErrors.client,
                                        Object.keys(validationErrors.client).filter(k => !k.startsWith('billing')),
                                      ),
                                    )}
                                </>
                              }
                            >
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <FormInputText
                                    name="client.billingCompany"
                                    control={control}
                                    label={i18next.t('offertemplate-client-billing-company')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInputText
                                    name="client.billingFirstname"
                                    control={control}
                                    label={i18next.t('offertemplate-client-billing-firstname')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInputText
                                    name="client.billingLastname"
                                    control={control}
                                    label={i18next.t('offertemplate-client-billing-lastname')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormInputText
                                    name="client.billingAddress"
                                    control={control}
                                    label={i18next.t('offertemplate-client-billing-address')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInputText
                                    name="client.billingZip"
                                    control={control}
                                    label={i18next.t('offertemplate-client-billing-zip')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInputText
                                    name="client.billingCity"
                                    control={control}
                                    label={i18next.t('offertemplate-client-billing-city')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormInputCountryDropdown
                                    name="client.billingCountry"
                                    control={control}
                                    label={i18next.t('offertemplate-client-billing-country')}
                                    required
                                    disabled={props.disabled || !props.canChangeClientData}
                                  />
                                </Grid>
                              </Grid>
                            </SimpleAccordion>
                          </Grid>
                        </Grid>
                    </SimpleAccordion>
                  </Grid>}
                  <Grid item xs={12}>
                    <SimpleAccordion header={`${i18next.t('offertemplate-section-additional-info')}`}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <FormInputText textFieldProps={{
                            multiline: true,
                            rows: 4,
                          }} name="additionalInfo1" control={control} label={i18next.t('offertemplate-additionalinfo1')} disabled={!props.disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText textFieldProps={{
                            multiline: true,
                            rows: 4,
                          }} name="additionalInfo2" control={control} label={i18next.t('offertemplate-additionalinfo2')} disabled={!props.disabled} />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText textFieldProps={{
                            multiline: true,
                            rows: 4,
                          }} name="additionalInfo3" control={control} label={i18next.t('offertemplate-additionalinfo3')} disabled={!props.disabled} />
                        </Grid>
                      </Grid>
                    </SimpleAccordion>
                  </Grid>
                  {advanced && (
                    <>
                      <Grid item xs={12}>
                        <SimpleAccordion header={`${i18next.t('offertemplate-section-document')}`}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormInputText name="title" control={control} label={i18next.t('offertemplate-title')} disabled={!props.disabled} />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputText name="filename" control={control} label={i18next.t('offertemplate-filename')} disabled={!props.disabled} />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputCheckbox
                                name="includeEmptyLineItems"
                                control={control}
                                label={i18next.t('offertemplate-includeemptylineitems')}
                                disabled={!props.disabled}
                              />
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAccordion header={`${i18next.t('offertemplate-section-header')}`}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormInputText
                                name="pageOptions.headerTemplate"
                                label={i18next.t('offertemplate-header')}
                                textFieldProps={{
                                  multiline: true,
                                  rows: 10,
                                }}
                                control={control}
                                disabled={!props.disabled}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputText
                                name="pageOptions.footerTemplate"
                                label={i18next.t('offertemplate-footer')}
                                textFieldProps={{
                                  multiline: true,
                                  rows: 10,
                                }}
                                control={control}
                                disabled={!props.disabled}
                              />
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAccordion
                          header={`${i18next.t('offertemplate-section-page')}`}
                          icon={<>{hintValidationAlert(validationErrors.pageOptions)}</>}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <FormInputNumber name="pageOptions.marginTop" label={i18next.t('offertemplate-marginTop')} control={control} required disabled={!props.disabled} />
                            </Grid>
                            <Grid item xs={6}>
                              <FormInputNumber
                                name="pageOptions.marginBottom"
                                label={i18next.t('offertemplate-marginBottom')}
                                control={control}
                                required
                                disabled={!props.disabled}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormInputNumber
                                name="pageOptions.marginLeft"
                                label={i18next.t('offertemplate-marginLeft')}
                                control={control}
                                required
                                disabled={!props.disabled}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormInputNumber
                                name="pageOptions.marginRight"
                                label={i18next.t('offertemplate-marginRight')}
                                control={control}
                                required
                                disabled={!props.disabled}
                              />
                            </Grid>
                          </Grid>
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAccordion header={`${i18next.t('offertemplate-section-css')}`}>
                          <FormInputText
                            name="pageOptions.cssStyles"
                            label={i18next.t('offertemplate-css')}
                            textFieldProps={{
                              multiline: true,
                              rows: 10,
                            }}
                            control={control}
                            disabled={!props.disabled}
                          />
                        </SimpleAccordion>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <DragDropContext
                          onDragEnd={result => {
                            if (result.destination) {
                              const contentBlocks = getValues('contentBlocks');
                              const [reorderedItem] = contentBlocks.splice(result.source.index, 1);
                              contentBlocks.splice(result.destination.index, 0, reorderedItem);
                              _reassignSequence(contentBlocks);
                            }
                          }}
                        >
                          <Droppable droppableId="characters">
                            {(provided: any) => (
                              <Grid container spacing={3} {...provided.droppableProps} ref={provided.innerRef} className="characters">
                                {contentBlocksFields.map((cb, index) => (
                                  <Draggable key={`${cb.id}_${cb.sequence}`} draggableId={`${index}_s`} index={index} isDragDisabled={props.disabled || !props.canChangeStructure || cb.type === 'LINE_ITEMS' || cb.type === 'CANCELLATION_ITEMS'}>
                                    {(provided: any) => (
                                      <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <EditorAccordion
                                          cssStyles={watch('pageOptions.cssStyles') || ''}
                                          contentBlock={cb}
                                          showHtmlTab={true}
                                          fullWidth={fullWidth}
                                          contentBlockIndex={index}
                                          control={control}
                                          onDelete={onContentBlockDelete}
                                          onTotalsChanged={onTotalsChanged}
                                          onLineItemChanged={onLineItemChanged}
                                          onLineItemCopyPrices={onLineItemCopyPrices}
                                          onLineItemPriceComponentChanged={onLineItemPriceComponentChanged}
                                          onLineItemSKUAdded={onLineItemSKUAdded}
                                          onLineItemFreeAdded={onLineItemFreeAdded}
                                          disabled={props.disabled}
                                          canChangePrices={props.canChangePrices}
                                          canChangeStructure={props.canChangeStructure}
                                          canChangeItems={props.canChangeItems}
                                        />
                                      </Grid>
                                    )}
                                  </Draggable>
                                ))}
                              </Grid>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Grid>
                    </>
                  )}
                  {!advanced &&
                    contentBlocksFields.map((cb, index) => {
                      if (cb.type === 'LINE_ITEMS' || cb.type === 'CANCELLATION_ITEMS') {
                        return (
                          <Grid item xs={12} key={`${cb.id}_${cb.sequence}_s`}>
                            <EditorAccordion
                              cssStyles={watch('pageOptions.cssStyles') || ''}
                              showHtmlTab={false}
                              fullWidth={fullWidth}
                              contentBlock={cb}
                              contentBlockIndex={index}
                              control={control}
                              onDelete={onContentBlockDelete}
                              onTotalsChanged={onTotalsChanged}
                              onLineItemChanged={onLineItemChanged}
                              onLineItemCopyPrices={onLineItemCopyPrices}
                              onLineItemPriceComponentChanged={onLineItemPriceComponentChanged}
                              onLineItemSKUAdded={onLineItemSKUAdded}
                              onLineItemFreeAdded={onLineItemFreeAdded}
                              disabled={props.disabled}
                              canChangePrices={props.canChangePrices}
                              canChangeStructure={props.canChangeStructure}
                              canChangeItems={props.canChangeItems}
                            />
                          </Grid>
                        );
                      }
                      return null;
                    })}
                  <Grid item xs={12}>
                    <SimpleAccordion header={`${i18next.t('offertemplate-section-totals')}`} autoOpen>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {i18next.t('offertemplate-totals-net')}: {formatPrice(watch('totalPriceNet'), props.data.priceList.currency)}
                        </Grid>
                        {watch('agreedCancellationWaiveNet') && <Grid item xs={12}>
                          {i18next.t('invoice-cancellation-agreedwaive')}: {formatPrice(watch('agreedCancellationWaiveNet')!, props.data.priceList.currency)}
                        </Grid>}
                        {watch('manualCancellationWaiveNet') && <Grid item xs={12}>
                          {i18next.t('invoice-cancellation-manualwaive')}: {formatPrice(watch('manualCancellationWaiveNet')!, props.data.priceList.currency)}
                        </Grid>}
                        <Grid item xs={12}>
                          {i18next.t('offertemplate-totals-taxes')}: {formatPrice(watch('totalTax'), props.data.priceList.currency)}
                        </Grid>
                        <Grid item xs={12}>
                          {i18next.t('offertemplate-totals-gross')}: {formatPrice(watch('totalPriceGross'), props.data.priceList.currency)}
                        </Grid>
                      </Grid>
                    </SimpleAccordion>
                  </Grid>
                  {!props.disabled && props.canChangeStructure && <Grid item xs={12}>
                    <Button
                      sx={{ marginRight: 2 }}
                      variant="contained"
                      color="secondary"
                      startIcon={<PlusIcon />}
                      disabled={isSubmitting || isValidating || !contentBlocksFields.find(c => c.type === 'LINE_ITEMS') || !!contentBlocksFields.find(c => c.type === 'LINE_ITEMS' && c.day === -1)}
                      onClick={async () => {
                        const firstLineItemBlockIndex = contentBlocksFields.findIndex(c => c.type === 'LINE_ITEMS')
                        if (firstLineItemBlockIndex >= 0) {
                          const contentBlocks = [...getValues('contentBlocks')];
                          contentBlocks.splice(firstLineItemBlockIndex, 0, {
                            day: -1,
                            type: EOfferVersionContentBlockType.LINE_ITEMS,
                            sequence: -1,
                            template: contentBlocksFields[firstLineItemBlockIndex].template,
                            lineItems: [],
                            formFields: [],
                            cancellationRule: null
                          });
                          _reassignSequence(contentBlocks);
                        }
                      }}
                    >
                      {i18next.t('offertemplate-add-previousday')}
                    </Button>
                    <Button
                      sx={{ marginRight: 2 }}
                      variant="contained"
                      color="secondary"
                      startIcon={<PlusIcon />}
                      disabled={isSubmitting || isValidating || !contentBlocksFields.find(c => c.type === 'LINE_ITEMS')}
                      onClick={async () => {
                        const lastLineItemBlockIndex = contentBlocksFields.map(c => c.type === 'LINE_ITEMS').lastIndexOf(true)
                        if (lastLineItemBlockIndex >= 0) {
                          const contentBlocks = [...getValues('contentBlocks')];
                          contentBlocks.splice(lastLineItemBlockIndex + 1, 0, {
                            day: contentBlocksFields[lastLineItemBlockIndex].day! + 1,
                            type: EOfferVersionContentBlockType.LINE_ITEMS,
                            sequence: -1,
                            template: contentBlocksFields[lastLineItemBlockIndex].template,
                            lineItems: [],
                            formFields: [],
                            cancellationRule: null
                          });
                          _reassignSequence(contentBlocks);
                        }
                      }}
                    >
                      {i18next.t('offertemplate-add-day')}
                    </Button>
                  </Grid>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </QuickPriceCalculatorContext.Provider>
  );
}

export default function OfferVersion(props: OfferVersionProps) {
  const offerVersionQuery = useQuery(OFFERVERSION_VIEW_QUERY, {
    variables: { id: props.versionId },
  });  
  const offerVersionQuickPriceQuery = useQuery(OFFERVERSION_QUICKPRICE_QUERY, {
    variables: { id: props.versionId },
  });
  const offerVersionProductTextQuery = useQuery(OFFERVERSION_PRODUCTTEXT_QUERY, {
    variables: { id: props.versionId },
  });
  const taxTypesQuery = useQuery(TAXTYPES_LIST_FULL_QUERY)

  const loading = offerVersionQuickPriceQuery.loading || offerVersionProductTextQuery.loading || offerVersionQuery.loading || taxTypesQuery.loading;
  const error = offerVersionQuickPriceQuery.error || offerVersionProductTextQuery.error || offerVersionQuery.error || taxTypesQuery.error;

  if (loading) return <CircularProgress />;
  if (error) return <RedirectError err={error} />;

  const calc = props.offerSource === 'WIDGET' ? new QuickPriceCalculatorWidget() : new QuickPriceCalculatorLister()
  calc.hydrate(offerVersionQuickPriceQuery.data!.offerVersionQuickPriceDehydrate!);


  const pt = new ProductTextSelector();
  pt.hydrate(offerVersionProductTextQuery.data!.offerVersionProductTextDehydrate!);

  return (
    <OfferVersionForm
      quickPriceCalculator={calc}
      productTexts={pt}
      taxTypes={taxTypesQuery.data!.listTaxTypes}
      data={offerVersionQuery.data!.viewWlOfferVersion!}
      {...props}
    />
  );
}
